import React, { useEffect, useState } from "react";
import PageHeader from "../../../shared/PageHeader";
import Button from "../../../components/form/Button";
import Input from "../../../components/form/Input";
import SingleImageUpload from "../../../components/form/SingleImageUpload";
import { formValidate } from "../../../helpers/formValidate";
import useForm from "../../../hooks/useForm";
import { Validation } from "../../../helpers/Validation";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { addUser } from "../../../services/userService";
import { setTitle } from "../../../helpers/MetaTag";
import TextEditor from "../../../components/form/TextEditor";
import {
  loadSiteSettingsDetails,
  updateSiteSetting,
} from "../../../services/settingService";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getSettings } from "../../../redux/slice/settingsSlice";

const SiteSetting = () => {
  setTitle("Service Flows");
  const validation = {
    title: { required: true, message: "Please enter title !!!" },
    email: { required: true, message: "Please enter email !!!" },
    phone: { required: true, message: "Please enter phone no !!!" },
    address: { required: true, message: "Please enter address !!!" },
    copyright: { required: true, message: "Please enter copyright !!!" },
    appStore: { required: true, message: "Please enter appStore link !!!" },
    googlePlay: { required: true, message: "Please enter googlePlay link !!!" },
  };
  const { values, handleChange, handleSubmit, errors, setFieldsValue } =
    useForm({}, validation);
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [faviconUrl, setFaviconUrl] = useState();
  const [description, setDescription] = useState();
  const [details, setDetails] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    if (details) {
      setFieldsValue({
        title: details?.title || "",
        email: details?.email || "",
        phone: details?.phone || "",
        address: details?.address || "",
        copyright: details?.copyright || "",
        appStore: details?.appStore || "",
        faceBook: details?.faceBook || "",
        googlePlay: details?.googlePlay || "",
        twitter: details?.twitter || "",
        instagram: details?.instagram || "",
      });
      setImageUrl(details?.logoUrl);
      setFaviconUrl(details?.faviconUrl);
      setDescription(details?.description);
    }
  }, [details]);

  const onSubmit = async (values) => {
    setLoaded(true);
    try {
      const res = await updateSiteSetting({
        image: imageUrl,
        favicon: faviconUrl,
        title: values?.title || "",
        email: values?.email || "",
        phone: values?.phone || "",
        address: values?.address || "",
        copyright: values?.copyright || "",
        appStore: values?.appStore || "",
        faceBook: values?.faceBook || "",
        googlePlay: values?.googlePlay || "",
        twitter: values?.twitter || "",
        instagram: values?.instagram || "",
        description: description,
      });

      if (res?.data?.status === 200 || res?.data?.status === 201) {
        toast.info(res?.data?.message);
        dispatch(getSettings(res?.data?.data));
      } else {
        toast.info(
          res?.data?.message ||
            res?.data?.error ||
            res?.data?.errors ||
            "Something went wrong"
        );
      }

      setLoaded(false);
    } catch (err) {
      setLoaded(false);
      // console.error(err);
      toast.error("Somthing went wrong !!!");
    }
  };

  useEffect(() => {
    loadSiteSettingsDetails(setDetails);
  }, []);

  return (
    <div>
      <form onSubmit={(event) => handleSubmit(event, onSubmit)}>
        <PageHeader
          title={"Site Setting"}
          headerActions={() => (
            <div className="flex items-center space-x-3">
              <Button
                buttonHasLink={false}
                buttonType={"submit"}
                buttonIcon={"fa-light fa-floppy-disk"}
                buttonIconPosition={"left"}
                buttonClasses={"!bg-primary !text-org-silver"}
                buttonLabel={"Save"}
                isDisable={loaded}
              />
            </div>
          )}
        />
        <div className="py-2 px-6">
          <div className="w-full bg-white rounded-md shadow  py-5 px-4">
            <div className="grid grid-cols-4 gap-4 divide-x divide-slate-200">
              <div className="col-span-3">
                <div className="space-y-3">
                  <div className="grid grid-cols-12 gap-4">
                    <div className="relative col-span-6">
                      <Input
                        isInputGroup={true}
                        label={"Title"}
                        labelClasses={"!text-base"}
                        inputType={"text"}
                        value={values.title}
                        inputName={"title"}
                        onChange={handleChange}
                        inputPlaceholder={"Enter Title"}
                        {...formValidate(errors, "title")}
                      />
                    </div>
                    <div className="relative col-span-6">
                      <Input
                        isInputGroup={true}
                        label={"Email"}
                        labelClasses={"!text-base"}
                        inputType={"text"}
                        value={values.email}
                        inputName={"email"}
                        onChange={handleChange}
                        inputPlaceholder={"Enter Email"}
                        {...formValidate(errors, "email")}
                      />
                    </div>
                    <div className="relative col-span-6">
                      <Input
                        isInputGroup={true}
                        label={"Phone"}
                        labelClasses={"!text-base"}
                        inputType={"text"}
                        value={values.phone}
                        inputName={"phone"}
                        onChange={handleChange}
                        inputPlaceholder={"Enter Phone"}
                        {...formValidate(errors, "phone")}
                      />
                    </div>
                    <div className="relative col-span-6">
                      <Input
                        isInputGroup={true}
                        label={"Address"}
                        labelClasses={"!text-base"}
                        inputType={"text"}
                        value={values.address}
                        inputName={"address"}
                        onChange={handleChange}
                        inputPlaceholder={"Enter Address"}
                        {...formValidate(errors, "address")}
                      />
                    </div>
                    <div className="relative col-span-6">
                      <Input
                        isInputGroup={true}
                        label={"Copyright"}
                        labelClasses={"!text-base"}
                        inputType={"text"}
                        value={values.copyright}
                        inputName={"copyright"}
                        onChange={handleChange}
                        inputPlaceholder={"Enter Copyright"}
                        {...formValidate(errors, "copyright")}
                      />
                    </div>
                    <div className="relative col-span-6">
                      <Input
                        isInputGroup={true}
                        label={"AppStore"}
                        labelClasses={"!text-base"}
                        inputType={"text"}
                        value={values.appStore}
                        inputName={"appStore"}
                        onChange={handleChange}
                        inputPlaceholder={"Enter AppStore Link"}
                        {...formValidate(errors, "appStore")}
                      />
                    </div>

                    <div className="relative col-span-6">
                      <Input
                        isInputGroup={true}
                        label={"Google Play"}
                        labelClasses={"!text-base"}
                        inputType={"text"}
                        value={values.googlePlay}
                        inputName={"googlePlay"}
                        onChange={handleChange}
                        inputPlaceholder={"Enter Google Play Link"}
                        {...formValidate(errors, "googlePlay")}
                      />
                    </div>
                    <div className="relative col-span-6">
                      <Input
                        isInputGroup={true}
                        label={"FaceBook"}
                        labelClasses={"!text-base"}
                        inputType={"text"}
                        value={values.faceBook}
                        inputName={"faceBook"}
                        onChange={handleChange}
                        inputPlaceholder={"Enter FaceBook Link"}
                        {...formValidate(errors, "faceBook")}
                      />
                    </div>
                    <div className="relative col-span-6">
                      <Input
                        isInputGroup={true}
                        label={"Instagram"}
                        labelClasses={"!text-base"}
                        inputType={"text"}
                        value={values.instagram}
                        inputName={"instagram"}
                        onChange={handleChange}
                        inputPlaceholder={"Enter Instagram Link"}
                        {...formValidate(errors, "Instagram")}
                      />
                    </div>
                    <div className="relative col-span-6">
                      <Input
                        isInputGroup={true}
                        label={"twitter"}
                        labelClasses={"!text-base"}
                        inputType={"text"}
                        value={values.twitter}
                        inputName={"twitter"}
                        onChange={handleChange}
                        inputPlaceholder={"Enter Twitter Link"}
                        {...formValidate(errors, "twitter")}
                      />
                    </div>
                    <div className="relative col-span-12">
                      <TextEditor
                        label="Description"
                        value={description}
                        onChange={(e) => setDescription(e)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-1 pl-4 gap-3">
                <div className="relative">
                  <SingleImageUpload
                    label={"Logo"}
                    size={"lg"}
                    image={imageUrl}
                    setImage={setImageUrl}
                    accept={["jpg", "png", "jpeg"]}
                  />
                </div>
                <div className="relative">
                  <SingleImageUpload
                    label={"Fav Icon"}
                    size={"lg"}
                    image={faviconUrl}
                    setImage={setFaviconUrl}
                    accept={["jpg", "png", "jpeg"]}
                  />
                </div>
              </div>
            </div>
            <div className="mt-8 pt-4 border-t border-slate-200">
              <div className="flex items-center space-x-3">
                <Button
                  buttonHasLink={false}
                  buttonType={"submit"}
                  buttonIcon={"fa-light fa-floppy-disk"}
                  buttonIconPosition={"left"}
                  buttonClasses={"!bg-primary !text-org-silver"}
                  buttonLabel={"Save"}
                  isDisable={loaded}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default SiteSetting;
