import React from "react";
import { classNames } from "../../helpers/classNames";

const FaqItem = ({ data, handleChange = () => {} }) => {
  return (
    <>
      <div className="relative bg-slate-100 rounded-lg overflow-hidden shadow border border-white">
        <button
          type="button"
          className="w-full text-left flex items-center py-4 px-5 border-none bg-transparent"
          onClick={() => handleChange(data)}
        >
          <div className="w-full flex-grow flex-shrink">
            <h4 className="text-sm sm:text-base text-primaryAlt font-semibold !leading-tight">
              {data?.question || ""}
            </h4>
          </div>
          <div className="w-8 h-8 flex-grow-0 flex-shrink-0 ml-auto rounded-md text-slate-400 transition-all duration-200 overflow-hidden flex items-center justify-center hover:bg-slate-100 hover:text-slate-800 ">
            <i
              className={classNames(
                "fa-regular fa-fw fa-chevron-right",
                data.isOpen ? "rotate-90" : ""
              )}
            ></i>
          </div>
        </button>
        <div
          className={classNames(
            "px-5 border-slate-100 overflow-hidden transition-all duration-200",
            data.isOpen
              ? "max-h-[2000px] py-3   md:py-6 border-t opacity-100 visible bg-white"
              : "max-h-0 opacity-0 invisible"
          )}
        >
          <div
            className="text-[13px] sm:text-sm xl:text-[15px] text-slate-600 space-y-4 leading-6"
            dangerouslySetInnerHTML={{ __html: data?.answer || ""}}
          ></div>
        </div>
      </div>
    </>
  );
};

export default FaqItem;
