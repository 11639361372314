import React, { useCallback, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import DefaultSidebar from "../shared/DefaultSidebar";
import Logo from "../assets/images/logo.png";
import Fav from "../assets/images/favicon.png";
import DefaultAdminHeader from "../shared/DefaultAdminHeader";
import { useDispatch, useSelector } from "react-redux";
import { userProfile } from "../services/userService";
import { setProfileDetails } from "../redux/slice/profileSlice,";
import { loadSiteSettingsDetails } from "../services/settingService";
import { getSettings } from "../redux/slice/settingsSlice";

const InnerLayout = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const token = localStorage.getItem("auth_token");
  const [profileData, setprofileData] = useState(null);
  const [loaded, setloaded] = useState(true);
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profile.profileData);

  // function favIconChange() {
  //   let link = document.querySelector("link[rel~='icon']");
  //   if (!link) {
  //     link = document.createElement("link");
  //     link.rel = "icon";
  //     document.getElementsByTagName("head")[0].appendChild(link);
  //   }
  //   link.href = settings?.favicon?.url;
  // }
  // favIconChange();

  useEffect(() => {
    userProfile().then((res) => {
      if (res?.success) {
        dispatch(setProfileDetails(res?.docs));
      }
    });
  }, []);

  const loggedInUser = {
    name: profile && profile?.name,
    image: profile && profile?.imageUrl,
  };

  const menuData = {
    logo: Logo,
    fav: Fav,
  };

  useEffect(() => {
    loadSiteSettingsDetails().then((res) => {
      if (res?.data?.status === 200) {
        dispatch(getSettings(res.data.docs));
      }
    });
  }, []);

  return (
    <>
      <div className="relative flex w-full h-screen overflow-hidden">
        <div
          className={
            "fixed h-screen flex transition-all duration-200 z-10 " +
            (isMenuOpen
              ? "-translate-x-full lg:translate-x-0 w-60 lg:w-16"
              : "translate-x-0 w-60 lg:w-60")
          }
        >
          <DefaultSidebar isMenuOpen={isMenuOpen} data={menuData} />
        </div>
        <div
          className={
            "relative w-full flex flex-col transition-all duration-200 " +
            (isMenuOpen ? "p-0 lg:pl-16" : "p-0 lg:pl-60")
          }
        >
          <DefaultAdminHeader
            isMenuOpen={isMenuOpen}
            setIsMenuOpen={setIsMenuOpen}
            isToggleIcon={true}
            siteData={"AdminPannel"}
            data={menuData}
            userData={loggedInUser}
          />
          <div className="relative w-full h-full max-h-[calc(100vh-4rem)] px-6 pt-0 pb-6 overflow-auto scroll-smooth scrollbar bg-slate-100">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};

export default InnerLayout;
