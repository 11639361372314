import React, { useEffect, useState } from "react";
import { classNames } from "../../helpers/classNames";

const Pagination = ({
  pageCount = 0,
  onPageChange = () => {},
  currentPage = 1,
  paginationClasses,
}) => {
  const [selectedPage, setSelectedPage] = useState(1);
  const [totalPagesList, setTotalPagesList] = useState([]);

  useEffect(() => {
    if (currentPage) {
      setSelectedPage(currentPage);
    } else {
      setSelectedPage(1);
    }
  }, [currentPage]);


  function generatePaginationList(selectedPage, totalPages) {
    const maxPagesToShow = 7; // You can adjust this number as needed
    const pages = [];

    if (totalPages <= maxPagesToShow) {
      // If total pages are less than or equal to the max pages to show,
      // display all pages from 1 to totalPages
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      // Calculate the range of pages to display around the selected page
      const pagesToShow = maxPagesToShow - 2; // Exclude the first and last page

      // Determine the start and end points for the pagination list
      let startPage = Math.max(1, selectedPage - Math.floor(pagesToShow / 2));
      let endPage = Math.min(totalPages, startPage + pagesToShow - 1);

      // Adjust startPage and endPage if near the beginning or end of the list
      if (endPage - startPage < pagesToShow - 1) {
        startPage = Math.max(1, endPage - pagesToShow + 1);
      }

      // Add ellipsis and first/last page numbers
      if (startPage > 1) {
        pages.push(1);
        if (startPage > 2) pages.push("...");
      }
      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }
      if (endPage < totalPages) {
        if (endPage < totalPages - 1) pages.push("...");
        pages.push(totalPages);
      }
    }

    return pages;
  }

  useEffect(() => {
    setTotalPagesList(generatePaginationList(selectedPage, pageCount));
  }, [selectedPage, pageCount]);

  return (
    <div className={classNames("flex", paginationClasses)}>
      <div className="flex gap-1 w-fit">
        <div
          className="flex justify-center items-center text-primary text-lg cursor-pointer"
          // onClick={() => {
          //   if (currentPage > 1) {
          //     onPageChange({ selected: 1 });
          //   }
          // }}
        >
          ⏴
        </div>
        {totalPagesList &&
          Array.isArray(totalPagesList) &&
          totalPagesList?.length > 0 &&
          totalPagesList.map((number) => (
            <>
              {/* {console.log(number, "numbererr")} */}
              <div
                key={number}
                onClick={() => {
                  if (number === "...") {
                    return;
                  } else {
                    setSelectedPage(number);
                    if (typeof onPageChange === "function") {
                      onPageChange({ selected: number });
                    }
                  }
                }}
                className={` flex justify-center items-center ${
                  number === "..."
                    ? ""
                    : "cursor-pointer font-semibold border border-primary w-10 h-10 rounded-md "
                }${
                  selectedPage === number
                    ? "bg-primary text-org-border"
                    : "text-primary"
                }`}
              >
                {number}
              </div>
            </>
          ))}
        <div
          className="cursor-pointer flex justify-center items-center text-primary text-lg"
          // onClick={() => {
          //   if (currentPage === pageCount - 1) {
          //     return;
          //   } else if (currentPage !== pageCount - 1) {
          //     setSelectedPage(pageCount - 1);
          //     if (typeof onPageChange === "function") {
          //       onPageChange({ selected: pageCount - 1 });
          //     }
          //   }
          // }}
        >
          ⏵
        </div>
      </div>
    </div>
  );
};

export default Pagination;
