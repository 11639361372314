import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useForm from "../../hooks/useForm";
import { Validation } from "../../helpers/Validation";
import Button from "../../components/form/Button";
import Input from "../../components/form/Input";
import { formValidate } from "../../helpers/formValidate";
import { setTitle } from "../../helpers/MetaTag";
import { addUser, userDetails, userProfile } from "../../services/userService";
import SingleImageUpload from "../../components/form/SingleImageUpload";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setProfileDetails } from "../../redux/slice/profileSlice,";

const Profile = () => {
  setTitle("Service Flows");
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const dispatch = useDispatch();
  const [detailsData, setDetailsData] = useState({ loading: false, data: {} });
  const validation = {
    name: { required: true, message: "Please enter your name !!!" },
    email: { required: true, message: "Please enter your email !!!" },
    password: {
      required: detailsData?.data?.id ? false : true,
      message: "Please enter your password !!!",
    },
  };
  const { values, handleChange, handleSubmit, errors, setFieldsValue } =
    useForm({}, validation);


  const validatePassword = (password) => {
    const errors = [];
    if (password.length < 8) {
      errors.push("Password must be at least 8 characters long.");
    }
    if (!/(?=.*\d)/.test(password)) {
      errors.push("Password must contain at least one digit.");
    }
    if (!/(?=.*[a-z])/.test(password)) {
      errors.push("Password must contain at least one lowercase letter.");
    }
    if (!/(?=.*[A-Z])/.test(password)) {
      errors.push("Password must contain at least one uppercase letter.");
    }
    if (!/(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])/.test(password)) {
      errors.push(
        "Password must contain at least one special character (!@#$%^&*()_+-=[]{};':\"\\|,.<>/?)."
      );
    }

    return errors;
  };
  const getDetails = useCallback(() => {
    setDetailsData({ loading: true, data: {} });
    userProfile().then((res) => {
      if (res?.status === 200) {
        setDetailsData({ loading: true, data: res?.docs });
        setFieldsValue({
          name: res?.docs?.name || "",
          email: res?.docs?.email || "",
        });
        setImageUrl(res?.docs?.imageUrl);
      }
    });
  }, []);

  useEffect(() => {
    getDetails();
  }, [getDetails]);

  const onSubmit = async (values) => {
    setLoaded(true);
    try {
      if (!Validation("name", values.name)) {
        toast.error("Name cannot be empty or contain only spaces.");
        setLoaded(false);
        return;
      }
      if (!Validation("email", values.email)) {
        toast.error("Invalid email format. Please enter a valid email.");
        setLoaded(false);
        return;
      }
      if (values.password) {
        const passwordErrors = validatePassword(values.password);
        if (passwordErrors.length > 0) {
          toast.error(passwordErrors.join(" "));
          setLoaded(false);
          return;
        }
      }
      if (
        values.password &&
        !Validation("confirmPassword", values.confirmpassword, values.password)
      ) {
        toast.error("Confirm password and password doesn`t match");
        setLoaded(false);
        return;
      }
      const res = await addUser({
        id: detailsData?.data?.id,
        image: imageUrl,
        name: values?.name,
        email: values?.email,
        roleCode: "admin",
        password: values?.password,
        password_confirmation: values?.confirmpassword,
      });

      if (res?.data?.status === 200 || res?.data?.status === 201) {
        toast.info(res?.data?.message);
        dispatch(setProfileDetails(res?.data?.data));
      } else {
        toast.info(
          res?.data?.message || res?.data?.errors || "Something went wrong"
        );
      }

      setLoaded(false);
    } catch (err) {
      setLoaded(false);
      // console.error(err);
      toast.error("Somthing went wrong !!!");
    }
  };

  return (
    <div className="space-y-4 w-full px-6 my-4">
      <div className="relative bg-white shadow-md rounded-lg">
        <div className="w-full mx-auto p-4 bg-white shadow-md rounded-lg">
          <form onSubmit={(event) => handleSubmit(event, onSubmit)}>
            <div className="font-semibold text-2xl">My Profile</div>
            <div className="grid grid-cols-4 gap-4 divide-x divide-slate-200">
              <div className="col-span-3">
                <div className="space-y-3">
                  <div className="grid grid-cols-12 gap-4">
                    <div className="relative col-span-6">
                      <Input
                        isInputGroup={true}
                        inputGroupIcon={"fa-regular fa-user"}
                        inputGroupPosition={"left"}
                        label={"Name"}
                        labelClasses={"!text-base"}
                        inputType={"text"}
                        value={values.name}
                        inputName={"name"}
                        onChange={handleChange}
                        inputPlaceholder={"Enter Name"}
                        {...formValidate(errors, "name")}
                      />
                    </div>
                    <div className="relative col-span-6">
                      <Input
                        isInputGroup={true}
                        inputGroupIcon={"fa-solid fa-envelope"}
                        inputGroupPosition={"left"}
                        label={"Email"}
                        labelClasses={"!text-base"}
                        inputType={"text"}
                        value={values.email}
                        inputName={"email"}
                        onChange={handleChange}
                        inputPlaceholder={"Enter Email"}
                        {...formValidate(errors, "email")}
                      />
                    </div>
                    <div className="relative col-span-6">
                      <Input
                        isInputGroup={true}
                        inputGroupIcon={"fa-solid fa-key"}
                        inputGroupPosition={"left"}
                        label={"Password"}
                        inputPlaceholder={"Enter  Password"}
                        labelClasses={"!text-base"}
                        inputType={"password"}
                        value={values.password}
                        inputName={"password"}
                        onChange={handleChange}
                        {...formValidate(errors, "password")}
                      />
                    </div>
                    <div className="relative col-span-6">
                      <Input
                        isInputGroup={true}
                        inputGroupIcon={"fa-solid fa-key"}
                        inputGroupPosition={"left"}
                        label={"Confirm Password"}
                        inputPlaceholder={"Enter Confirm Password"}
                        labelClasses={"!text-base"}
                        inputType={"password"}
                        value={values.confirmpassword}
                        inputName={"confirmpassword"}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-1 pl-4 gap-3">
                <div className="relative">
                  <SingleImageUpload
                    label={"User Image"}
                    size={"lg"}
                    image={imageUrl}
                    setImage={setImageUrl}
                    accept={["jpg", "png", "jpeg"]}
                  />
                </div>
              </div>
            </div>
            <div className="mt-8 pt-4 border-t border-slate-200">
              <div className="flex items-center space-x-3">
                {/* <Button
                  buttonHasLink={true}
                  buttonLink={"/admin/admin-list"}
                  buttonType={"button"}
                  buttonIcon={"fa-regular fa-xmark"}
                  buttonIconPosition={"left"}
                  buttonLabel={"Cancel"}
                  buttonClasses={
                    "!bg-white !text-primary !border-primary !border-2"
                  }
                /> */}
                <Button
                  buttonHasLink={false}
                  buttonType={"submit"}
                  buttonIcon={"fa-light fa-floppy-disk"}
                  buttonIconPosition={"left"}
                  buttonClasses={"!bg-primary !text-org-silver"}
                  buttonLabel={"Save"}
                  isDisable={loaded}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Profile;
