import React, { useCallback, useEffect, useState } from "react";
import PageHeader from "../../../shared/PageHeader";
import Button from "../../../components/form/Button";
import Input from "../../../components/form/Input";
import SingleImageUpload from "../../../components/form/SingleImageUpload";
import { formValidate } from "../../../helpers/formValidate";
import useForm from "../../../hooks/useForm";
import { Validation } from "../../../helpers/Validation";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { addUser, userDetails } from "../../../services/userService";
import { setTitle } from "../../../helpers/MetaTag";
import TextEditor from "../../../components/form/TextEditor";
import { addPage, pageDeatils } from "../../../services/pageService";
import TextAreaAutoSize from "../../../components/form/TextareaAutoSize";

const ManagePage = () => {
  setTitle("Service Flows");
  const { id } = useParams();
  const validation = {
    name: { required: true, message: "Please enter name !!!" },
    // title: { required: true, message: "Please enter title !!!" },
    // subTitle: { required: true, message: "Please enter sub title !!!" },
  };
  const { values, handleChange, handleSubmit, errors, setFieldsValue } =
    useForm({}, validation);
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [detailsData, setDetailsData] = useState({ loading: false, data: {} });
  const [description, setDescription] = useState();
  const [titles, setTitles] = useState();
  const [subTitle, setSubTitle] = useState();

  const getDetails = useCallback(() => {
    if (id) {
      setDetailsData({ loading: true, data: {} });
      pageDeatils({ id }).then((res) => {
        if (res?.status === 200) {
          setDetailsData({ loading: true, data: res?.docs });
          console.log(res, "resss");
          setFieldsValue({
            name: res?.docs?.name || "",
            title: res?.docs?.title || "",
            subTitle: res?.docs?.subTitle || "",
          });
          setImageUrl(res?.docs?.imageUrl);
          setDescription(res?.docs?.description);
          setTitles(res?.docs?.title);
          setSubTitle(res?.docs?.subTitle);
        }
      });
    }
  }, [id]);

  useEffect(() => {
    getDetails();
  }, [getDetails]);

  const onSubmit = async (values) => {
    setLoaded(true);
    try {
      if (!titles) {
        toast.error("Please enter title !!!");
        setLoaded(false);
        return;
      }
      // if (!description) {
      //   toast.error("Please enter description !!!");
      //   setLoaded(false);
      //   return;
      // }
      const res = await addPage({
        id: id,
        image: imageUrl,
        name: values?.name,
        title: titles,
        subTitle: subTitle,
        description: description,
      });

      if (res?.data?.status === 200 || res?.data?.status === 201) {
        navigate("/admin/pages");
        toast.info(res?.data?.message);
      } else {
        toast.info(
          res?.data?.message ||
            res?.data?.errors ||
            res?.data?.error ||
            "Something went wrong"
        );
      }

      setLoaded(false);
    } catch (err) {
      setLoaded(false);
      // console.error(err);
      toast.error("Somthing went wrong !!!");
    }
  };

  return (
    <div>
      <form onSubmit={(event) => handleSubmit(event, onSubmit)}>
        <PageHeader
          title={detailsData?.data?.id ? "Edit Page" : "Add New Page"}
          headerActions={() => (
            <div className="flex items-center space-x-3">
              <Button
                buttonHasLink={true}
                buttonLink={"/admin/pages"}
                buttonType={"button"}
                buttonIcon={"fa-regular fa-xmark"}
                buttonIconPosition={"left"}
                buttonLabel={"Cancel"}
                buttonClasses={"!bg-white !text-primary"}
              />
              <Button
                buttonHasLink={false}
                buttonType={"submit"}
                buttonIcon={"fa-light fa-floppy-disk"}
                buttonIconPosition={"left"}
                buttonClasses={"!bg-primary !text-org-silver"}
                buttonLabel={"Save"}
                isDisable={loaded}
              />
            </div>
          )}
        />
        <div className="py-2 px-6">
          <div className="w-full bg-white rounded-md shadow  py-5 px-4">
            <div className="grid grid-cols-4 gap-4 divide-x divide-slate-200">
              <div className="col-span-3">
                <div className="space-y-3">
                  <div className="grid grid-cols-12 gap-4">
                    <div className="relative col-span-12">
                      <Input
                        isInputGroup={true}
                        label={"Name"}
                        labelClasses={"!text-base"}
                        inputType={"text"}
                        value={values.name}
                        inputName={"name"}
                        onChange={handleChange}
                        inputPlaceholder={"Enter Name"}
                        {...formValidate(errors, "name")}
                      />
                    </div>
                    <div className="relative col-span-12">
                      {/* <Input
                        isInputGroup={true}
                        label={"Title"}
                        labelClasses={"!text-base"}
                        inputType={"text"}
                        value={values.title}
                        inputName={"title"}
                        onChange={handleChange}
                        inputPlaceholder={"Enter Title"}
                        {...formValidate(errors, "title")}
                      /> */}
                      <TextAreaAutoSize
                        label={"Title"}
                        labelClasses={"!text-base"}
                        inputValue={titles}
                        inputName={"title"}
                        onChange={(e) => setTitles(e.target.value)}
                        inputPlaceholder={"Enter Title"}
                      />
                    </div>
                    <div className="relative col-span-12">
                      {/* <Input
                        isInputGroup={true}
                        label={"Sub Title"}
                        labelClasses={"!text-base"}
                        inputType={"text"}
                        value={values.subTitle}
                        inputName={"subTitle"}
                        onChange={handleChange}
                        inputPlaceholder={"Enter Sub Title"}
                        {...formValidate(errors, "subTitle")}
                      /> */}
                      <TextAreaAutoSize
                        label={"Sub Title"}
                        labelClasses={"!text-base"}
                        inputValue={subTitle}
                        inputName={"subTitle"}
                        onChange={(e) => setSubTitle(e.target.value)}
                        inputPlaceholder={"Enter Sub Title"}
                      />
                    </div>
                    <div className="relative col-span-12">
                      <TextEditor
                        label="Description"
                        value={description}
                        onChange={(e) => setDescription(e)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-1 pl-4 gap-3">
                <div className="relative">
                  <SingleImageUpload
                    label={"Image"}
                    size={"lg"}
                    image={imageUrl}
                    setImage={setImageUrl}
                    accept={["jpg", "png", "jpeg"]}
                  />
                </div>
              </div>
            </div>
            <div className="mt-8 pt-4 border-t border-slate-200">
              <div className="flex items-center space-x-3">
                <Button
                  buttonHasLink={true}
                  buttonLink={"/admin/pages"}
                  buttonType={"button"}
                  buttonIcon={"fa-regular fa-xmark"}
                  buttonIconPosition={"left"}
                  buttonLabel={"Cancel"}
                  buttonClasses={
                    "!bg-white !text-primary !border-primary !border-2"
                  }
                />
                <Button
                  buttonHasLink={false}
                  buttonType={"submit"}
                  buttonIcon={"fa-light fa-floppy-disk"}
                  buttonIconPosition={"left"}
                  buttonClasses={"!bg-primary !text-org-silver"}
                  buttonLabel={"Save"}
                  isDisable={loaded}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ManagePage;
