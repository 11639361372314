import { apiRequest } from "../util/apiUtils";

export const loadSiteSettingsDetails = async (setDetails = () => {}) => {
  try {
    const res = await apiRequest("get", `siteSettings`);

    if (res?.data?.status === 200) {
      setDetails(res?.data?.docs);
    }
    return res;
  } catch (err) {
    return err;
  }
};

export const updateSiteSetting = async (params) => {
  try {
    const res = await apiRequest(
      "post",
      "siteSettings/edit",
      {
        body: { ...params },
      },
      "multipart/form-data"
    );
    return res;
  } catch (error) {
    return error;
  }
};
