import React, { useCallback, useEffect, useState } from "react";
import moment from "moment";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Table from "../../../components/elements/table/Table";
import { changeFaqStatus, getFaqList } from "../../../services/faqService";
import { status_filter } from "../../../constant/Constant";
import Select from "../../../components/form/Select";
import Input from "../../../components/form/Input";
import Button from "../../../components/form/Button";
import Modal from "../../../components/modal/Modal";
import { setTitle } from "../../../helpers/MetaTag";
import {
  changeServiceStatus,
  deleteService,
  getServiceList,
} from "../../../services/servicePageService";
import AddEditService from "./AddEditService";
import DeleteModal from "../../../components/elements/DeleteModal";

const ServiceList = () => {
  setTitle("Service Flows");
  const navigate = useNavigate();
  const [limit, setLimit] = useState({ name: "10 Items", value: 10 });
  const [status, setStatus] = useState("all");
  const [featured, setFeatured] = useState(" ");
  const [offset, setOffset] = useState(0);
  const [sort, setSort] = useState("-createdAt");
  const [currentPage, setCurrentPage] = useState(1);
  const [pagination, setPagination] = useState({
    hasNextPage: false,
    hasPrevPage: false,
  });
  const [statusFilterData, setStatusFilterData] = useState(status_filter || []);
  const [keyword, setKeyword] = useState("");
  //   const search = useDebounce(keyword, 300);
  const [list, setList] = useState({
    loading: true,
    data: [],
    pageCount: 0,
    totalItem: 0,
  });
  const [count, setCount] = useState(0);
  const [selectedItems, setSelectedItems] = useState([]);
  //   const [statusFilterData, setStatusFilterData] = useState(status_filter || []);
  const [deleteModal, setDeleteModal] = useState({
    isOpen: false,
    id: null,
  });
  const [deleteComplete, setDeleteComplete] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [detailsData, setDetailsData] = useState({});
  const data = [
    { name: "All Items", value: "All" },
    { name: "5 Items", value: 5 },
    { name: "10 Items", value: 10 },
    { name: "20 Items", value: 20 },
    { name: "30 Items", value: 30 },
    { name: "50 Items", value: 50 },
    { name: "100 Items", value: 100 },
  ];

  //Page data limit function
  const handleChange = (value) => {
    if (value?.value !== "All") {
      setOffset(0);
      setLimit(value);
      setSelectedItems([]);
    } else {
      setOffset(0);
      setLimit({ name: "All Items", value: count });
      setCurrentPage(1);
      setSelectedItems([]);
    }
  };

  const datas = [
    {
      questions: "SXCDVFBGNHGfdc",
      answer: "l;kjhgfdghjkjhgfd",
    },
    {
      questions: "SXCDVFBGNHGfdc",
      answer: "l;kjhgfdghjkjhgfd",
    },
    {
      questions: "SXCDVFBGNHGfdc",
      answer: "l;kjhgfdghjkjhgfd",
    },
  ];

  //   loading the member list >>>>>>>>>
  const loadList = useCallback(() => {
    setList((pre) => ({ ...pre, data: [], loading: true }));
    getServiceList({
      ordering: sort,
      keyword: keyword,
      limit: limit?.value,
      offset: currentPage,
    }).then((res) => {
      if (res && res?.docs?.success) {
        setList({
          loading: false,
          data: res.docs?.data,
          pageCount: res?.docs?.metadata?.totalPages,
          keyword: keyword,
          totalItem: res?.docs?.metadata?.totalDocs,
        });
        setCount(res?.docs?.metadata?.totalDocs);
        setPagination({
          hasNextPage: res?.docs?.metadata?.hasNextPage,
          hasPrevPage: res?.docs?.metadata?.hasPrevPage,
        });
      } else {
        setList((pre) => ({ ...pre, data: datas, loading: false }));
        toast.error(res?.message);
      }
    });
  }, [limit, status, sort, featured, currentPage, count, keyword]);

  useEffect(() => {
    loadList();
  }, [loadList]);

  // Pagination Logic
  // useEffect(() => {
  //   if (list?.data?.length <= 0 && pagination?.hasNextPage) {
  //     setCurrentPage(currentPage);
  //   } else if (list?.data?.length <= 0 && !pagination?.hasPrevPage) {
  //     setCurrentPage(1);
  //   } else if (list?.data?.length <= 0) {
  //     setCurrentPage(currentPage - 1);
  //   }
  // }, [list?.data.length]);

  //Refresh Function

  const refreshData = () => {
    setLimit({ name: "10 Items", value: 10 });
    setKeyword("");
    setSelectedItems([]);
  };

  //Table Heading Data >>>>>>>>>

  const tableHeadData = [
    {
      _id: 1,
      name: "Icon",
      align: "left",
      isSort: false,
      isFilter: false,
      isFilterSearch: false,
    },
    {
      _id: 2,
      name: "Name",
      align: "left",
      isSort: true,
      isFilter: false,
      isFilterSearch: false,
      onSort: (val) => {
        if (val === "DESC") {
          setSort("-name");
        } else {
          setSort("name");
        }
      },
    },
    {
      _id: 3,
      name: "Status",
      align: "left",
      isFilter: false,
      isFilterSearch: false,
      isSort: false,
      filterData: statusFilterData,
      onFilter: (data) => {
        setStatus(data?.map((item) => item?.value));
        setCurrentPage(1);
        setSelectedItems([]);
      },
    },
    {
      _id: 4,
      name: "Created At",
      align: "left",
      isFilter: false,
      isSort: false,
    },
    {
      _id: 5,
      name: "Action",
      align: "left",
      isFilter: false,
      isSort: false,
    },
  ];

  //   Status Change Api Call >>>>>>>>>>

  const handleStatusChange = async (data) => {
    changeServiceStatus({
      id: data?.id,
      status: data?.status === 1 ? 2 : 1,
    }).then((res) => {
      if (res?.status === 200) {
        setList((pre) => ({
          ...pre,
          data: pre.data.map((item) =>
            item.id === data.id
              ? {
                  ...item,
                  status: item.status === 1 ? 2 : 1,
                }
              : item
          ),
        }));
        toast.success("Status Change Successful");
      } else {
        toast.error("Somthing Went Wrong");
      }
    });
  };

  const addNew = () => {
    setIsOpen(true);
  };
  const editData = (item) => {
    navigate(`/admin/pages/edit/${item}`);
  };

  // Table Body Data >>>>>>>>>
  const tableData = list?.data?.map((item, index) => ({
    _id: item?._id,
    rowData: [
      {
        _id: 1,
        type: "text",
        align: "left",
        cellClass: "!min-w-[100px]",
        data: item?.icon ? (
          <>
            <div className="relative text-3xl font-bold">
              <i className={item?.icon}></i>
            </div>
          </>
        ) : (
          "N/A"
        ),
      },
      {
        _id: 2,
        type: "text",
        align: "left",
        cellClass: "!min-w-[100px]",
        data: item?.name || "N/A",
      },
      {
        _id: 3,
        type: "status",
        align: "left",
        cellClass: "!min-w-[100px]",
        statusLabel: item?.status === 1 ? "Active" : "In Active",
        statusType: item?.status === 1 ? "success" : "danger",
        functions: () => handleStatusChange(item),
      },
      {
        _id: 4,
        type: "text",
        align: "left",
        cellClass: "!min-w-[100px]",
        data: item?.createdAt
          ? moment(item?.createdAt).format("MM-DD-YYYY")
          : "N/A",
      },
      {
        _id: 5,
        type: "action",
        align: "left",
        cellClass: "w-[5%]",
        actionData: [
          {
            _id: 1,
            name: "Edit",
            icon: "fa-regular fa-pen-to-square",
            standout: false,
            onClick: () => {
              setIsOpen(true);
              setDetailsData(item);
            },
          },
          {
            _id: 2,
            name: "Delete",
            icon: "fa-regular fa-trash-can",
            standout: true,
            onClick: () => {
              setDeleteModal({
                isOpen: true,
                id: item?.id,
              });
            },
          },
        ],
      },
    ],
  }));

  // data delte api call
  const onDelete = (item) => {
    setDeleteComplete(true);
    if (deleteModal?.id) {
      try {
        deleteService({ id: deleteModal?.id }).then((res) => {
          if (res?.status === 200) {
            toast?.success(res?.message);
            setList((prevList) => ({
              ...prevList,
              data: prevList.data.filter((item) => item.id !== deleteModal.id),
            }));
            setDeleteModal({
              isOpen: false,
              id: null,
            });
          } else {
            toast.error(res?.message || res?.error);
          }
        });
        setDeleteComplete(false);
      } catch (error) {
        setDeleteComplete(false);
        // toast.error(error);
      }
    }
  };

  console.log(list?.data, "listtaxtttxatxtft");

  return (
    <>
      <div className="w-full py-4 px-6">
        <div className="font-semibold text-3xl m-1 text-rose-500">Service</div>
        <div className="mb-6 flex gap-3">
          <div className="w-40">
            <Select
              dropdownData={data}
              placeholder={"Select Limit"}
              dropdownButtonClass={"!h-10 !rounded-md "}
              selectedValue={limit}
              onChange={handleChange}
            />
          </div>
          <div className="w-80">
            <Input
              inputType={"text"}
              isInputGroup={true}
              inputGroupPosition={"left"}
              inputGroupIcon={"fa-regular fa-search"}
              inputPlaceholder={"Search"}
              inputClasses={"!pl-3"}
              value={keyword}
              onChange={(e) => {
                setKeyword(e.target.value);
                setCurrentPage(1);
                setSelectedItems([]);
              }}
            />
          </div>
          {/* {selectedItems.length > 0 && (
            <>
              <div className="relative">
                <Button
                  buttonHasLink={false}
                  buttonIcon={"fa-regular fa-badge-check"}
                  buttonIconPosition={"left"}
                  buttonClasses={"!bg-org-green !border-org-border !text-white"}
                  buttonLabel={"Active"}
                  buttonLabelClasses={"font-medium !text-sm"}
                  buttonFunction={handleActiveStatusToggle}
                />
              </div>
            </>
          )}
          {selectedItems.length > 0 && (
            <>
              <div className="relative">
                <Button
                  buttonHasLink={false}
                  buttonIcon={"fa-regular fa-circle-xmark"}
                  buttonIconPosition={"left"}
                  buttonClasses={"!bg-org-red !border-org-border !text-white"}
                  buttonLabel={"In Active"}
                  buttonLabelClasses={"font-medium !text-sm"}
                  buttonFunction={handleInactiveStatusToggle}
                />
              </div>
            </>
          )} */}

          <div className="ml-auto">
            <Button
              buttonHasLink={false}
              buttonIcon={"fa-regular fa-plus"}
              buttonIconPosition={"left"}
              buttonClasses={
                "!bg-org-theme !border-org-theme !text-white hover:!bg-secondaryColor !border-org-theme  hover:!text-white"
              }
              buttonLabel={"Add Service"}
              buttonLabelClasses={"font-medium !text-sm"}
              buttonFunction={addNew}
            />
          </div>
        </div>
        <div className="-mx-8">
          <Table
            tableHeadData={tableHeadData}
            tableData={tableData}
            containerClasses={"px-6 min-h-[calc(100vh-136px)]"}
            isLoder={list?.loading}
            pageCount={list?.pageCount}
            currentPage={currentPage}
            onPageChange={(val) => {
              setOffset(limit * val?.selected);
              setCurrentPage(val?.selected);
              setSelectedItems([]);
            }}
            isOrderingDisabled={true}
            originalData={[...list.data]}
            // handleOrdering={handleOrdering}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            isCheckbox={false}
            setOriginalData={(data) => setList((cur) => ({ ...cur, data }))}
          />
        </div>
      </div>
      <Modal
        title={"Add New Service"}
        open={isOpen}
        size="xl2"
        onClose={() => setIsOpen(false)}
      >
        <AddEditService
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          setCount={setCount}
          count={count}
          setDetailsData={setDetailsData}
          detailsData={detailsData}
        />
      </Modal>
      <DeleteModal
        open={deleteModal?.isOpen}
        onClose={() => setDeleteModal({ isOpen: false })}
        loading={deleteComplete}
        deleteHandler={onDelete}
      />
    </>
  );
};

export default ServiceList;
