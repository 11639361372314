import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useForm from "../../../hooks/useForm";
import { Validation } from "../../../helpers/Validation";
import Button from "../../../components/form/Button";
import Input from "../../../components/form/Input";
import { formValidate } from "../../../helpers/formValidate";
import { setTitle } from "../../../helpers/MetaTag";
import {
  addUser,
  userDetails,
  userProfile,
} from "../../../services/userService";
import SingleImageUpload from "../../../components/form/SingleImageUpload";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setProfileDetails } from "../../../redux/slice/profileSlice,";
import PageHeader from "../../../shared/PageHeader";
import { addPaidServiceProvider } from "../../../services/MakePaymentService";

const ServiceProviderPayment = () => {
  setTitle("Service Flows");
  const [loaded, setLoaded] = useState(false);
  const validation = {
    milestoneId: { required: true, message: "Please enter milestoneId !!!" },
    email: { required: true, message: "Please enter your email !!!" },
  };
  const {
    values,
    handleChange,
    handleSubmit,
    errors,
    setFieldsValue,
    resetField,
  } = useForm({}, validation);

  const onSubmit = async (values) => {
    setLoaded(true);
    try {
      const res = await addPaidServiceProvider({
        id: values?.milestoneId,
        email: values?.email,
      });

      if (res?.data?.status === 200 || res?.data?.status === 201) {
        toast.info(res?.data?.message);
        resetField({ milestoneId: "", email: "" });
      } else {
        toast.info(
          res?.data?.message || res?.data?.errors || "Something went wrong"
        );
      }

      setLoaded(false);
    } catch (err) {
      setLoaded(false);
      // console.error(err);
      toast.error("Somthing went wrong !!!");
    }
  };

  return (
    <div className="relative bg-white shadow-md rounded-lg">
      <div className="w-full mx-auto p-4 bg-white shadow-md rounded-lg">
        <div className="py-4 space-y-4">
          <div className="font-semibold text-3xl text-rose-500">
            Paid To Service Provider
          </div>
          <div className="font-normal text-sm text-gray-700">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce
            aliquam urna dui, ac mollis metus varius dapibus. Vestibulum
            scelerisque volutpat gravida. Ut id nisl orci. Morbi tincidunt erat
            porttitor bibendum ullamcorper.
          </div>
        </div>

        <form onSubmit={(event) => handleSubmit(event, onSubmit)}>
          <div className="grid grid-cols-4 gap-4">
            <div className="col-span-4">
              <div className="space-y-3">
                <div className="grid grid-cols-12 gap-4">
                  <div className="relative col-span-6">
                    <Input
                      isInputGroup={true}
                      inputGroupIcon={"fa-solid fa-envelope"}
                      inputGroupPosition={"left"}
                      label={"User Email"}
                      labelClasses={"!text-base"}
                      inputType={"text"}
                      value={values.email}
                      inputName={"email"}
                      onChange={handleChange}
                      inputPlaceholder={"Enter Email"}
                      {...formValidate(errors, "email")}
                    />
                  </div>
                  <div className="relative col-span-6">
                    <Input
                      isInputGroup={true}
                      inputGroupIcon={"fa-solid fa-id-card"}
                      inputGroupPosition={"left"}
                      label={"Milestone Id"}
                      labelClasses={"!text-base"}
                      inputType={"text"}
                      value={values.milestoneId}
                      inputName={"milestoneId"}
                      onChange={handleChange}
                      inputPlaceholder={"Enter Milestone Id"}
                      {...formValidate(errors, "milestoneId")}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-8 pt-4 border-t border-slate-200">
            <div className="flex items-center space-x-3">
              {/* <Button
                  buttonHasLink={true}
                  buttonLink={"/admin/admin-list"}
                  buttonType={"button"}
                  buttonIcon={"fa-regular fa-xmark"}
                  buttonIconPosition={"left"}
                  buttonLabel={"Cancel"}
                  buttonClasses={
                    "!bg-white !text-primary !border-primary !border-2"
                  }
                /> */}
              <Button
                buttonHasLink={false}
                buttonType={"submit"}
                buttonIcon={"fa-light fa-floppy-disk"}
                buttonIconPosition={"left"}
                buttonClasses={"!bg-primary !text-org-silver"}
                buttonLabel={"Make Payment"}
                isDisable={loaded}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ServiceProviderPayment;
