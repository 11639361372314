import { apiRequest } from "../util/apiUtils";

export const getSuccessMessage = async (params) => {
  try {
    const res = await apiRequest(
      "get",
      `quotation/quotation-accepted/${params?.id}`,
      {}
    );
    return res;
  } catch (err) {
    return err;
  }
};

export const getMilestoneSuccessMessage = async (params) => {
  try {
    const res = await apiRequest("get", `milestone/dispute/${params?.id}`, {});
    return res;
  } catch (err) {
    return err;
  }
};
