import { apiRequest } from "../util/apiUtils";

export const getPageList = async (params) => {
  try {
    const res = await apiRequest("get", "/page/list", {
      queries: params,
    });
    return res?.data;
  } catch (error) {
    return error;
  }
};
export const changePageStatus = async (payload = {}) => {
  try {
    const res = await apiRequest("post", "page/statusChange", {
      body: payload,
    });
    return res;
  } catch (err) {
    return err;
    throw err;
  }
};

export const addPage = async (params) => {
  try {
    const res = await apiRequest(
      params.id ? "post" : "post",
      params.id ? "page/edit" : "page/add",
      {
        body: { ...params },
      },
      "multipart/form-data"
    );
    return res;
  } catch (error) {
    return error;
  }
};

export const deletePage = async (params) => {
  try {
    const res = await apiRequest("get", `page/delete/${params?.id}`, {
      body: {},
    });
    return res?.data;
  } catch (error) {
    return error;
  }
};

export const pageDeatils = async (params) => {
  try {
    const res = await apiRequest("get", `page/details/${params?.id}`, {
      queries: params,
    });
    return res?.data;
  } catch (err) {
    throw err;
  }
};
