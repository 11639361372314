import { apiRequest } from "../util/apiUtils";

export const getFaqList = async (params) => {
  try {
    const res = await apiRequest("get", "/faq/list", {
      queries: params,
    });
    return res?.data;
  } catch (error) {
    return error;
  }
};

export const changeFaqStatus = async (payload = {}) => {
  try {
    const res = await apiRequest("post", "faq/statusChange", {
      body: payload,
    });
    return res;
  } catch (err) {
    return err;
    throw err;
  }
};

export const addFaq = async (params) => {
  try {
    const res = await apiRequest(
      params.id ? "post" : "post",
      params.id ? "faq/edit" : "faq/add",
      {
        body: {...params},
      },
    );
    return res;
  } catch (error) {
    return error;
  }
};

export const deleteFaq = async (params) => {
  try {
    const res = await apiRequest("get", `faq/delete/${params?.id}`, {
      body: {},
    });
    return res?.data;
  } catch (error) {
    return error;
  }
};
