import React, { useCallback, useEffect, useState } from "react";
import Image from "../components/elements/Image";
import QueryImage from "../assets/images/query.png";
import { classNames } from "../helpers/classNames";
import { toast } from "react-toastify";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import BannerImage from "../assets/images/banner-image-2.png";
import { getSuccessMessage } from "../services/successPageService";

const EmailTemplateSuccessPage = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("qid");
  const [list, setList] = useState({
    loading: true,
    data: {
      message: "Loading....",
      icon: "fa-duotone fa-loader animate-spin-slow",
      color: "text-secondaryColor",
    },
  });

  // Loading User list >>>>>>>>>
  const loadList = useCallback(() => {
    setList((pre) => ({
      loading: true,
      data: {
        message: "Loading....",
        icon: "fa-duotone fa-loader animate-spin-slow",
        color: "text-secondaryColor",
      },
    }));
    if (id) {
      getSuccessMessage({
        id: id,
      }).then((res) => {
        if (res?.data.status === 400) {
          setList({
            loading: false,
            data: {
              message: res?.data?.message || res?.data?.error,
              icon: "fa-solid fa-circle-xmark",
              color: "text-error",
            },
          });
        } else if (res?.data.status === 200) {
          setList({
            loading: false,
            data: {
              message: res?.data?.message,
              icon: "fas fa-badge-check",
              color: "text-shamrock-700",
            },
          });
        } else {
          setList({
            loading: false,
            data: {
              message: res?.data?.message || res?.data?.error,
              icon: "fa-solid fa-circle-xmark",
              color: "text-error",
            },
          });
        }
      });
    }
  }, [id]);

  useEffect(() => {
    loadList();
  }, [loadList]);

  // console.log(id, "idddd");

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-10 items-center mt-8 mb-8">
      <div className="sticky hidden md:block">
        <div className="w-96 md:w-80 lg:w-96 h-96 md:h-80 lg:h-96 overflow-hidden mx-auto">
          <Image
            src={BannerImage}
            alt={""}
            width={"100%"}
            height={"100%"}
            // effect={"blur"}
            classes={"object-cover object-bottom lg:object-center"}
          />
        </div>
      </div>

      <div className="w-80 h-80 rounded-lg shadow-lg flex flex-col items-center justify-center bg-white m-auto">
        <div className="flex items-center justify-center">
          <i
            className={`text-6xl ${list?.data?.color} ${list?.data?.icon}`}
          ></i>
        </div>
        <p className="mt-6 text-md text-primary font-bold">
          {list?.data ? list?.data?.message : ""}
        </p>
      </div>
    </div>
  );
};

export default EmailTemplateSuccessPage;
