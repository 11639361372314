import { apiRequest } from "../util/apiUtils";

export const addQuery = async (params) => {
  try {
    const res = await apiRequest(
      "post",
      "scnquery/add",
      {
        body: params,
      },
      "multipart/form-data"
    );
    return res?.data;
  } catch (error) {
    return error;
  }
};
