import { apiRequest } from "../util/apiUtils";

export const addPaidHoldingAccount = async (params) => {
  try {
    const res = await apiRequest("post", "milestone/paid-to-holding-account", {
      body: { ...params },
    });
    return res;
  } catch (error) {
    return error;
  }
};

export const addPaidServiceProvider = async (params) => {
    try {
      const res = await apiRequest("post", "milestone/paid-to-service-provider", {
        body: { ...params },
      });
      return res;
    } catch (error) {
      return error;
    }
  };
