import React, { useCallback, useEffect, useState } from "react";
import { setTitle } from "../helpers/MetaTag";
import MainBanner from "../components/sections/MainBanner";
import AboutUs from "../components/sections/AboutUs";
import Faq from "../components/sections/Faq";
import ContactUs from "../components/sections/ContactUs";
import ContactForm from "../components/partials/ContactForm";

import BannerImage from "../assets/images/banner-image-2.png";
import AboutImage from "../assets/images/aboutus.jpg";
import FaqImage from "../assets/images/faq.png";
import PlayStore from "../assets/images/play-store.png";
import AppStore from "../assets/images/app-store.png";
import {
  getFaqFrontList,
  getHomePageData,
  getServiceFrontList,
} from "../services/homeService";
import { loadSiteSettingsDetails } from "../services/settingService";

const Home = () => {
  setTitle("Service Flows");

  const [homeData, setHomeData] = useState({});
  const [aboutData, setAboutData] = useState({});
  const [faqData, setFaqData] = useState({});
  const [contactData, setContactData] = useState({});
  const [siteData, setsiteData] = useState({});

  // const bannerData = {
  //   image: BannerImage,
  //   title:
  //     "Manage your <span class='text-primaryAlt block text-4xl sm:text-3xl lg:text-4xl xl:text-6xl 2xl:text-7xl font-extrabold'>Service Contracts</span> in easy ways.",
  //   subtitle: "Let's work together",
  //   description:
  //     "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters.",
  //   actionLink: "/",
  //   actionLabel: "Get Started",
  // };

  // const aboutDatas = {
  //   title: "About us",
  //   image: AboutImage,
  //   subtitle:
  //     "<p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters</p>",
  //   description:
  //     "<p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p> <p>The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search.</p> <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search.</p>",
  //   services: [
  //     {
  //       _id: 1,
  //       name: "Service Qoutes",
  //       icon: "",
  //       description: "Create, view and manage all your in progress qoutes.",
  //     },
  //     {
  //       _id: 2,
  //       name: "Service Contracts",
  //       icon: "",
  //       description: "View and manage all your accepted qoutes.",
  //     },
  //     {
  //       _id: 3,
  //       name: "Payments",
  //       icon: "",
  //       description: "View and manage all your payments.",
  //     },
  //     {
  //       _id: 4,
  //       name: "Customers",
  //       icon: "",
  //       description: "View all your customers and their qoutes and payments.",
  //     },
  //   ],
  // };

  // const faqDatas = {
  //   title: "Ask us Anything",
  //   description: "Have a question? We're here to assist you.",
  //   image: FaqImage,
  //   faqs: [
  //     {
  //       _id: 1,
  //       title:
  //         "It is a long established fact that a reader will be distracted?",
  //       description:
  //         "<p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p> <p>The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search.</p>",
  //       isOpen: true,
  //     },
  //     {
  //       _id: 2,
  //       title:
  //         "The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters?",
  //       description:
  //         "<p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p> <p>The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search.</p> <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search.</p>",
  //       isOpen: false,
  //     },
  //     {
  //       _id: 3,
  //       title:
  //         "It is a long established fact that a reader will be distracted by the readable content?",
  //       description:
  //         "<p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p> <p>The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search.</p> <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search.</p>",
  //       isOpen: false,
  //     },
  //     {
  //       _id: 4,
  //       title:
  //         "Many desktop publishing packages and web page editors now use Lorem Ipsum?",
  //       description:
  //         "<p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p> <p>The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search.</p> <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search.</p>",
  //       isOpen: false,
  //     },
  //     {
  //       _id: 5,
  //       title: "Lorem Ipsum as their default model text, and a search?",
  //       description:
  //         "<p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p> <p>The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search.</p> <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search.</p>",
  //       isOpen: false,
  //     },
  //   ],
  // };
  const [faq, setFaq] = useState([]);

  const handleChange = (data) => {
    setFaq(
      faq?.map((fq) => {
        if (data?.id === fq?.id) {
          return { ...fq, isOpen: !fq.isOpen };
        } else {
          return { ...fq, isOpen: data?.isOpen ? fq.isOpen : false };
        }
      })
    );
  };

  const contactUsData = {
    title: contactData?.title || "Contact Us",
    subtitle:
      contactData?.subTitle ||
      "We’d love to hear from you. Please fill out this form or shoot us an email.",
    formTitle: "Feel free to contact us.",
    contactForm: <ContactForm />,
    contact: [
      {
        icon: "fa-regular fa-envelope",
        name: "Email",
        description: "Our friendly team is here to help.",
        type: "email",
        link: "",
        data: siteData?.email || "",
      },
      {
        icon: "fa-regular fa-phone",
        name: "Phone",
        description: "Mon-Fri from 8am to 5pm.",
        type: "phone",
        link: "",
        data: siteData?.phone || "",
      },
    ],
    socialMenuTitle: "Follow us on",
    socialMenu: [
      {
        _id: 1,
        name: "Facebook",
        link: siteData?.faceBook || "",
        icon: "fa-facebook-f",
      },
      {
        _id: 2,
        name: "Instagram",
        link: siteData?.instagram || "",
        icon: "fa-instagram",
      },
      {
        _id: 3,
        name: "Twitter",
        link: siteData?.twitter || "",
        icon: "fa-x-twitter",
      },
    ],
    appDownload: {
      title: "App Download",
      menu: [
        {
          _id: 1,
          name: "Play Store",
          link: siteData?.googlePlay || "",
          image: PlayStore,
        },
        {
          _id: 2,
          name: "App Store",
          link: siteData?.appStore || "",
          image: AppStore,
        },
      ],
    },
  };

  const loadHomeData = useCallback(() => {
    getHomePageData({
      slug: "home",
    }).then((res) => {
      if (res?.status === 200) {
        setHomeData(res?.docs);
      }
    });
  }, []);

  useEffect(() => {
    loadHomeData();
  }, [loadHomeData]);

  const loadAboutData = useCallback(() => {
    getHomePageData({
      slug: "about",
    }).then((res) => {
      if (res?.status === 200) {
        setAboutData(res?.docs);
      }
    });
  }, []);

  useEffect(() => {
    loadAboutData();
  }, [loadAboutData]);

  const loadFaqData = useCallback(() => {
    getHomePageData({
      slug: "faq",
    }).then((res) => {
      if (res?.status === 200) {
        setFaqData(res?.docs);
      }
    });
  }, []);

  useEffect(() => {
    loadFaqData();
  }, [loadFaqData]);

  //   loading the faq list >>>>>>>>>
  const loadList = useCallback(() => {
    getFaqFrontList({ status: 1 }).then((res) => {
      if (res && res.success) {
        const updatedData = res.data.map((item, index) => ({
          ...item,
          isOpen: index === 0 ? true : false,
          id: index,
        }));
        setFaq(updatedData);
      }
    });
  }, []);

  useEffect(() => {
    loadList();
  }, [loadList]);

  const loadContactData = useCallback(() => {
    getHomePageData({
      slug: "contact",
    }).then((res) => {
      if (res?.status === 200) {
        setContactData(res?.docs);
      }
    });
  }, []);

  useEffect(() => {
    loadContactData();
  }, [loadContactData]);

  useEffect(() => {
    loadSiteSettingsDetails().then((res) => {
      if (res?.data?.status === 200) {
        setsiteData(res.data.docs);
      }
    });
  }, []);

  return (
    <>
      <MainBanner data={homeData} />
      <AboutUs data={aboutData} imagePosition={"left"} />
      <Faq data={faqData} handleChange={handleChange} faq={faq} />
      <ContactUs data={contactUsData} />
    </>
  );
};

export default Home;
