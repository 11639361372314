import React from 'react';
import Image from "../elements/Image";
import FaqItem from "../items/FaqItem";
import FaqSkeleton from '../loader/FaqSkeleton';
import { classNames } from "../../helpers/classNames";
import FaqImage from "../../assets/images/faq.png";

const Faq = ({ data, handleChange = () => { }, loader, faq }) => {

  return (
    <>
      {loader ? (<FaqSkeleton />) : (
        <section className="relative w-full py-0 xl:py-12 sm:py-0 z-0 bg-white" id="faq">
          <div className="relative w-full px-0 lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-9xl mx-auto py-10 xl:py-12 sm:py-8">
            <div className="space-y-5 lg:space-y-10">
              <div className="w-full max-w-5xl mx-auto text-center">
                <h2 className={classNames(
                  "text-3xl xl:text-4xl 2xl:text-5xl text-primaryAlt font-bold relative mb-10",
                  "before:content-[''] before:absolute before:-bottom-4 before:left-1/2 before:-translate-x-1/2 before:w-32 before:border-b-2 before:border-primaryAltFade/25",
                  "after:content-[''] after:absolute after:bottom-[-21px] after:left-1/2 after:-translate-x-1/2 after:border-[6px] after:border-secondaryColor after:rotate-45",
                )}
                  dangerouslySetInnerHTML={{ __html: data?.title || "" }}></h2>
                <div className="text-base xl:text-sm text-slate-500 space-y-4 font-light" dangerouslySetInnerHTML={{ __html: data?.subTitle || "" }}></div>
              </div>
              <div className="lg:grid lg:grid-cols-5 lg:gap-10">
                <div className="lg:col-span-2 hidden lg:block">
                  <div className="sticky">
                    <Image
                      src={data?.imageUrl}
                      // src={FaqImage}

                      alt={''} width={'100%'} height={'100%'} effect={'blur'} classes={'object-cover object-bottom lg:object-center'} />
                    {/* <div className="absolute top-0 left-0 w-full h-full z-0 bg-cyprus-500 opacity-60"></div>
                    <div className="absolute top-0 left-0 w-full h-full z-[1] pt-10 pb-40 lg:pb-10 px-10 space-y-4 flex flex-col justify-end">
                      <h2 className="font-MPlusRounded1c text-3xl lg:text-3xl xl:text-5xl text-white" dangerouslySetInnerHTML={{ __html: data.title }}></h2>
                      <div className="text-sm xl:text-base text-white/75 space-y-4" dangerouslySetInnerHTML={{ __html: data.description }}></div>
                    </div> */}
                  </div>
                </div>
                <div className="lg:col-span-3 px-5 py-4 sm:py-8 lg:p-0 lg:bg-transparent mt-0 lg:mt-0 relative z-10 rounded-t-5xl lg:rounded-none">
                  {faq?.length > 0 &&
                    <div className="space-y-4">
                      {faq?.map((item) => (
                        <FaqItem key={item._id} data={item} handleChange={handleChange} />
                      ))}
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Faq;