import React, { useCallback, useEffect, useState } from "react";
import { classNames } from "../../helpers/classNames";
import Image from "../elements/Image";
import Box from "../items/Box";
import { getServiceFrontList } from "../../services/homeService";

const AboutUs = ({ data }) => {
  const aboutRightData = [
    {
      icon: "fa-solid fa-quote-right",
      title: "service quotes",
      description: "Create, view and manage all your in progress quotes",
    },
    {
      icon: "fa-solid fa-file-contract",
      title: "service contracts",
      description: "View and manage all your acepted quotes",
    },
    {
      icon: "fa-solid fa-credit-card",
      title: "payments",
      description: "View and manage all your payments",
    },
    {
      icon: "fa-solid fa-users",
      title: "customers",
      description: "View all your customers and their quotes and payments",
    },
  ];

  const [service, setService] = useState([]);

  //   loading the member list >>>>>>>>>
  const loadList = useCallback(() => {
    getServiceFrontList({ status: 1 }).then((res) => {
      if (res && res?.success) {
        setService(res?.data);
      }
    });
  }, []);

  useEffect(() => {
    loadList();
  }, [loadList]);

  return (
    <>
      <section
        className="relative overflow-hidden w-full py-20 xl:py-12 md:py-8 bg-white z-0 after:absolute after:content-[''] after:w-full after:h-full after:top-0 after:right-0 after:bg-gradient-to-tl after:from-slate-100 after:z-[-1]"
        id="about"
      >
        <div className="relative w-full px-4 sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-9xl mx-auto">
          <div className="space-y-8">
            <div className="w-full max-w-5xl mx-auto text-center">
              <h2
                className={classNames(
                  "text-3xl xl:text-4xl 2xl:text-5xl text-primaryAlt font-bold relative mb-10",
                  "before:content-[''] before:absolute before:-bottom-4 before:left-1/2 before:-translate-x-1/2 before:w-32 before:border-b-2 before:border-primaryAltFade/25",
                  "after:content-[''] after:absolute after:bottom-[-21px] after:left-1/2 after:-translate-x-1/2 after:border-[6px] after:border-secondaryColor after:rotate-45"
                )}
                dangerouslySetInnerHTML={{ __html: data?.title || "" }}
              ></h2>
              <div
                className="text-base xl:text-sm text-slate-500 space-y-4 font-light"
                dangerouslySetInnerHTML={{ __html: data?.subTitle || "" }}
              ></div>
            </div>
            <div className="grid md:grid-cols-1 lg:grid-cols-2 gap-10 sm:gap-20 md:gap-5 lg:gap-10 items-center">
              <div className="relative w-full sm:mb-10">
                <div
                  className="text-sm md:text-lg xl:text-lg text-slate-400 space-y-4 font-normal leading-5 about-descriptions"
                  dangerouslySetInnerHTML={{ __html: data?.description || "" }}
                ></div>
              </div>
              <div className="relative w-full">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
                  {service &&
                    service.length > 0 &&
                    service?.map((item, index) => (
                      <Box key={index} data={item} />
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutUs;
