import React, { useEffect, useState } from "react";
import PageHeader from "../../../shared/PageHeader";
import Button from "../../../components/form/Button";
import Input from "../../../components/form/Input";
import SingleImageUpload from "../../../components/form/SingleImageUpload";
import { formValidate } from "../../../helpers/formValidate";
import useForm from "../../../hooks/useForm";
import { Validation } from "../../../helpers/Validation";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { addUser } from "../../../services/userService";
import TextEditor from "../../../components/form/TextEditor";
import { setTitle } from "../../../helpers/MetaTag";
import TextAreaAutoSize from "../../../components/form/TextareaAutoSize";
import { addService } from "../../../services/servicePageService";

const AddEditService = ({
  isOpen,
  setIsOpen = () => {},
  setCount = () => {},
  count,
  setDetailsData = () => {},
  detailsData,
}) => {
  setTitle("Service Flows");
  const validation = {
    name: { required: true, message: "Please enter name !!!" },
    icon: { required: true, message: "Please enter icon !!!" },
  };
  const { values, handleChange, handleSubmit, errors, setFieldsValue } =
    useForm({}, validation);
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(false);
  const [descriptionData, setDescriptionData] = useState();


  useEffect(() => {
    if (detailsData?.id) {
      setFieldsValue({
        name: detailsData?.name,
        icon: detailsData?.icon,
      });
      setDescriptionData(detailsData?.description);
    }
  }, [detailsData]);

  const onSubmit = async (values) => {
    setLoaded(true);
    try {
      const res = await addService({
        id: detailsData && detailsData?.id,
        name: values?.name,
        icon: values?.icon,
        description: descriptionData,
      });

      if (res?.data?.status === 200 || res?.data?.status === 201) {
        setIsOpen(false);
        setCount((pre) => pre + 1);
        toast.success(res?.data?.message);
        setDetailsData({});
      } else {
        toast.info(
          res?.data?.message ||
            res?.data?.errors ||
            res?.data?.error ||
            "Some thing went wrong"
        );
        setIsOpen(false);
        setDetailsData({});
      }

      setLoaded(false);
    } catch (err) {
      setLoaded(false);
      console.error(err);
      toast.error("Somthing went wrong !!!");
    }
  };

  return (
    <div>
      <form onSubmit={(event) => handleSubmit(event, onSubmit)}>
        <div className="py-2 px-6">
          <div className="grid grid-cols-4 gap-4">
            <div className="col-span-4">
              <div className="space-y-3">
                <div className="grid grid-cols-12 gap-4">
                  <div className="relative col-span-12">
                    <Input
                      isInputGroup={true}
                      inputGroupIcon={"fa-solid fa-text"}
                      inputGroupPosition={"left"}
                      label={"Name"}
                      labelClasses={"!text-base"}
                      inputType={"text"}
                      value={values.name}
                      inputName={"name"}
                      onChange={handleChange}
                      inputPlaceholder={"Enter Name"}
                      {...formValidate(errors, "name")}
                    />
                  </div>
                  <div className="relative col-span-12">
                    <Input
                      isInputGroup={true}
                      inputGroupIcon={"fa-solid fa-icons"}
                      inputGroupPosition={"left"}
                      label={"Icon"}
                      labelClasses={"!text-base"}
                      inputType={"text"}
                      value={values.icon}
                      inputName={"icon"}
                      onChange={handleChange}
                      inputPlaceholder={"Enter Icon"}
                      {...formValidate(errors, "icon")}
                    />
                  </div>
                  <div className="relative col-span-12">
                    <TextAreaAutoSize
                      label={"Description"}
                      labelClasses={"!text-base"}
                      inputValue={descriptionData}
                      inputName={"description"}
                      onChange={(e) => setDescriptionData(e.target.value)}
                      inputPlaceholder={"Enter Description"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-8 pt-4 border-t border-slate-200">
            <div className="flex items-center space-x-3 justify-end">
              <Button
                buttonHasLink={false}
                buttonType={"button"}
                buttonIcon={"fa-regular fa-xmark"}
                buttonIconPosition={"left"}
                buttonLabel={"Cancel"}
                buttonClasses={
                  "!bg-white !text-primary !border-primary !border-2"
                }
                buttonFunction={() => {
                  setIsOpen(false);
                  setDetailsData({});
                }}
              />
              <Button
                buttonHasLink={false}
                buttonType={"submit"}
                buttonIcon={"fa-light fa-floppy-disk"}
                buttonIconPosition={"left"}
                buttonClasses={"!bg-primary !text-org-silver"}
                buttonLabel={"Save"}
                isDisable={loaded}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddEditService;
