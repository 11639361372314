import React from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import AuthLayout from "./layouts/AuthLayout";
import DefaultLayout from "./layouts/DefaultLayout";

import Home from "./pages/Home";
import Query from "./pages/Query";
import EmailTemplateSuccessPage from "./pages/EmailTemplateSuccessPage";
import MilestoneSuccessPage from "./pages/DisputeSuccessPage";
import DisputeSuccessPage from "./pages/DisputeSuccessPage";
import FreeAuthRoute from "./routes/FreeAuthRoute";
import Login from "./pages/admin/AdminLogin";
import InnerLayout from "./layouts/InnerLayout";
import Dashboard from "./pages/admin/Dashboard";
import PageList from "./pages/admin/page/PageList";
import ManagePage from "./pages/admin/page/ManagePage";
import ProtectedRoute from "./routes/ProtectedRoute";
import UserList from "./pages/admin/user/UserList";
import AdminList from "./pages/admin/admins/AdminList";
import UserAddEdit from "./pages/admin/user/UserAddEdit";
import FaqList from "./pages/admin/faq/FaqList";
import SiteSetting from "./pages/admin/setting/SiteSetting";
import EmailTemplate from "./pages/admin/email/EmailTemplate";
import ServiceList from "./pages/admin/service/ServiceList";
import AdminAddEdit from "./pages/admin/admins/AdminAddEdit";
import Profile from "./pages/admin/Profile";
import EditEmailTemplate from "./pages/admin/email/EditEmailTemplate";
import MakePayment from "./pages/admin/makePyment/MakePayment";
import RaisedDispute from "./pages/RaisedDispute";

const CreateRoutes = () => {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Routes>
          <Route path="/admin">
            <Route
              path="/admin"
              exact
              element={
                <FreeAuthRoute title={"Sign In"}>
                  <Login />
                </FreeAuthRoute>
              }
            />
          </Route>
          {/* Front Page Route */}
          <Route path="/" element={<DefaultLayout isMenuData={true} />}>
            <Route
              path="/"
              exact
              element={
                <FreeAuthRoute title={"Home"}>
                  <Home />
                </FreeAuthRoute>
              }
            />
          </Route>
          <Route path="/Query" element={<DefaultLayout isMenuData={false} />}>
            <Route path="/Query" exact element={<Query />} />
          </Route>
          <Route
            path="/acceptquotes"
            element={<DefaultLayout isMenuData={false} />}
          >
            <Route
              path="/acceptquotes"
              exact
              element={<EmailTemplateSuccessPage />}
            />
          </Route>
          <Route
            path="/raisedispute"
            element={<DefaultLayout isMenuData={false} />}
          >
            <Route
              path="/raisedispute"
              exact
              // element={<DisputeSuccessPage />}
              element={<RaisedDispute />}
            />
          </Route>
          {/* <Route path="/dispute" element={<DefaultLayout isMenuData={false} />}>
            <Route path="/dispute" exact element={<RaisedDispute />} />
          </Route> */}

          {/* Admin Page Route */}
          <Route path="/admin/admin-list" element={<InnerLayout />}>
            <Route
              path="/admin/admin-list"
              exact
              element={
                <ProtectedRoute title={"Admin List"}>
                  <AdminList />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/admin/admin-add" element={<InnerLayout />}>
            <Route
              path="/admin/admin-add"
              exact
              element={
                <ProtectedRoute title={"User Add"}>
                  <AdminAddEdit />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/admin/admin-edit/:id" element={<InnerLayout />}>
            <Route
              path="/admin/admin-edit/:id"
              exact
              element={
                <ProtectedRoute title={"User Edit"}>
                  <AdminAddEdit />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/admin/user-list" element={<InnerLayout />}>
            <Route
              path="/admin/user-list"
              exact
              element={
                <ProtectedRoute title={"User List"}>
                  <UserList />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/admin/user-add" element={<InnerLayout />}>
            <Route
              path="/admin/user-add"
              exact
              element={
                <ProtectedRoute title={"User Add"}>
                  <UserAddEdit />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/admin/user-edit/:id" element={<InnerLayout />}>
            <Route
              path="/admin/user-edit/:id"
              exact
              element={
                <ProtectedRoute title={"User Edit"}>
                  <UserAddEdit />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/admin/pages" element={<InnerLayout />}>
            <Route
              path="/admin/pages"
              exact
              element={
                <ProtectedRoute title={"Pages"}>
                  <PageList />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/admin/services" element={<InnerLayout />}>
            <Route
              path="/admin/services"
              exact
              element={
                <ProtectedRoute title={"Service"}>
                  <ServiceList />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/admin/faq" element={<InnerLayout />}>
            <Route
              path="/admin/faq"
              exact
              element={
                <ProtectedRoute title={"Faqs"}>
                  <FaqList />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/admin/page-add" element={<InnerLayout />}>
            <Route
              path="/admin/page-add"
              exact
              element={
                <ProtectedRoute title={"Pages"}>
                  <ManagePage />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/admin/page-edit/:id" element={<InnerLayout />}>
            <Route
              path="/admin/page-edit/:id"
              exact
              element={
                <ProtectedRoute title={"Pages"}>
                  <ManagePage />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/admin/email-template" element={<InnerLayout />}>
            <Route
              path="/admin/email-template"
              exact
              element={
                <ProtectedRoute title={"User List"}>
                  <EmailTemplate></EmailTemplate>
                </ProtectedRoute>
              }
            />
          </Route>
          <Route
            path="/admin/email-template-edit/:id"
            element={<InnerLayout />}
          >
            <Route
              path="/admin/email-template-edit/:id"
              exact
              element={
                <ProtectedRoute title={"User List"}>
                  <EditEmailTemplate></EditEmailTemplate>
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/admin/site-setting" element={<InnerLayout />}>
            <Route
              path="/admin/site-setting"
              exact
              element={
                <ProtectedRoute title={"Site Setting"}>
                  <SiteSetting />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/admin/profile" element={<InnerLayout />}>
            <Route
              path="/admin/profile"
              exact
              element={
                <ProtectedRoute title={"Profile"}>
                  <Profile />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/admin/make-payment" element={<InnerLayout />}>
            <Route
              path="/admin/make-payment"
              exact
              element={
                <ProtectedRoute title={"Make Payment"}>
                  <MakePayment />
                </ProtectedRoute>
              }
            />
          </Route>
        </Routes>
      </ScrollToTop>
    </BrowserRouter>
  );
};

export default CreateRoutes;
