import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { classNames } from "../helpers/classNames";
import Image from "../components/elements/Image";
import Button from "../components/form/Button";
import UserDropdown from "./UserDropdown";
import LogoutModal from "../components/elements/LogoutModal"

const DefaultAdminHeader = ({
  data,
  isMenuData,
  isMenuOpen,
  setIsMenuOpen,
  userData,
}) => {
  const onPress = (e) => {
    e.preventDefault();
    const target = window.document.getElementById(
      e.currentTarget.href.split("#")[1]
    );
    if (target) {
      var headerOffset = 80;
      var elementPosition = target.getBoundingClientRect().top;
      var offsetPosition = elementPosition - headerOffset;

      window.scrollBy({
        behavior: "smooth",
        top: offsetPosition,
      });
    }
  };
  const [isOpen, setOpen] = useState(false);

  const menuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const dropdownMenu = [
    {
      _id: 1,
      label: "My Profile",
      link:"/admin/profile",
      icon: "fa-solid fa-user",
    },
    {
      _id: 2,
      label: "Log Out",
      icon: "fa-regular fa-fw fa-arrow-right-from-arc",
      onclick: (val) => {
        setOpen(true);
      },
    },
  ];

  return (
    <>
      <header className="sticky top-0 py-1 z-50 w-full flex items-center px-6  bg-primaryAlt  gap-4">
        <div className="relative flex gap-2">
          <Button
            buttonType={"button"}
            buttonIcon={"fa-solid fa-bars"}
            buttonIconPosition={"left"}
            buttonClasses={
              "px-0 w-10  !text-secondaryColor  hover:!text-slate-100"
            }
            buttonFunction={menuToggle}
          />
        </div>
        <div className="relative ml-auto flex justify-end items-center gap-3">
              <div className="relative  px-2 py-3 ">
                <UserDropdown
                  xPlacement={"right"}
                  userData={userData}
                  data={dropdownMenu}
                />
              </div>
            </div>
      </header>
      <LogoutModal isOpen={isOpen} setOpen={setOpen} />
    </>
  );
};

export default DefaultAdminHeader;
