import React, { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { usePopper } from "react-popper";
import { useRef, useState } from "react";
import { bottom, top, left, right } from "@popperjs/core";
import Search from "../../form/Search";
import Checkbox from "../../form/Checkbox";
import Radio from "../../form/Radio";
import { classNames } from "../../../helpers/classNames";
import Button from "../../form/Button";
import useClickOutside from "../../../hooks/useClickOutside";

const TableFilter = ({
  xPlacement,
  dropdownContainer,
  isFilterSearch,
  filterData = [],
  onFilter = () => {},
  filterSearch = () => {},
  ...props
}) => {
  const popperElRef = useRef(null);
  const [targetElement, setTargetElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [selected, setSelected] = useState([]);
  const [open, setOpen] = useState(false);

  const placements = {
    bottom: bottom,
    bottomLeft: "bottom-start",
    bottomRight: "bottom-end",
    top: top,
    topLeft: "top-start",
    topRight: "top-end",
    right: right,
    left: left,
    "": bottom,
  };

  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement: placements[xPlacement],
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 8],
        },
      },
    ],
  });

  useClickOutside(popperElRef, () => {
    setOpen(false);
  });

  const isAnySelected = selected.length > 0;

  return (
    <>
      <Menu as="div">
        {({}) => (
          <>
            <Menu.Button
              ref={setTargetElement}
              className="flex items-center"
              onClick={() => setOpen(!open)}
            >
              <div
                className={classNames(
                  "relative flex items-center justify-center text-sm w-6 h-6 focus:ring-0 transition-all duration-200",
                  isAnySelected ? "text-rose-400" : ""
                )}
              >
                <i className="fa-solid fa-fw fa-filter-list"></i>
              </div>
            </Menu.Button>
            <Transition
              show={open}
              leave="transition duration-75"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              className="z-50"
              beforeEnter={() => setPopperElement(popperElRef.current)}
              afterLeave={() => setPopperElement(null)}
              ref={popperElRef}
              style={styles.popper}
              {...attributes.popper}
            >
              <Menu.Items className="w-40 origin-top-right bg-white border-0 divide-y divide-slate-100 rounded-md shadow ring-0">
                {isFilterSearch && (
                  <>
                    <div className="py-2 px-3">
                      <Search
                        placeholder={"Search"}
                        iconClasses={""}
                        classes={"font-normal"}
                        onSearch={(e) => filterSearch(e)}
                      />
                    </div>
                  </>
                )}

                <div className="py-3 max-h-60 divide-y divide-slate-100 overflow-auto scroll-smooth scrollbar">
                  <div
                    onClick={() => {
                      if (selected?.length === filterData?.length) {
                        setSelected([]);
                      } else {
                        setSelected(filterData);
                      }
                    }}
                    className={classNames(
                      "cursor-pointer",
                      selected?.length === filterData?.length
                        ? "bg-slate-100 hover:bg-slate-200"
                        : "hover:bg-slate-100"
                    )}
                  >
                    <div className="relative py-2 px-3 hover:bg-slate-100 transition-all duration-200">
                      <Checkbox
                        checkboxName={"filter_checkbox"}
                        checkboxInputclassName={""}
                        isChecked={selected?.length === filterData?.length}
                        checkboxLableclassName={"font-normal text-xs"}
                        checkboxLabel={"All"}
                      />
                    </div>
                  </div>
                  {filterData.map((item, index) => (
                    <div
                      key={index?.toString()}
                      onClick={() => {
                        if (
                          selected
                            ?.map((item) => item?.value)
                            ?.includes(item?.value)
                        ) {
                          setSelected(
                            selected?.filter(
                              (s_item) => s_item?.value !== item?.value
                            )
                          );
                        } else {
                          setSelected((pre) => [...pre, item]);
                        }
                      }}
                      className={classNames(
                        "cursor-pointer",
                        selected?.some((data) => data?.value === item?.value)
                          ? "bg-slate-100 hover:bg-slate-200"
                          : "hover:bg-slate-100"
                      )}
                    >
                      {item.type === "checkbox" && (
                        <div className="relative py-2 px-3 hover:bg-slate-100 transition-all duration-200">
                          <Checkbox
                            checkboxName={"filter_checkbox"}
                            checkboxInputclassName={""}
                            isChecked={selected?.some(
                              (data) => data?.value === item?.value
                            )}
                            checkboxLabel={item.name}
                            checkboxLableclassName={"font-normal text-xs"}
                          />
                        </div>
                      )}
                      {item.type === "radio" && (
                        <div className="relative py-2 px-3 hover:bg-slate-100 transition-all duration-200">
                          <Radio
                            radioName={"filter_radio"}
                            radioInputclassName={""}
                            isChecked={item.isChecked}
                            radioLabel={item.name}
                            radioLableclassName={"font-normal text-xs"}
                          />
                        </div>
                      )}
                    </div>
                  ))}
                </div>
                <div className="flex justify-end gap-1">
                  <Button
                    buttonLabel={"Reset"}
                    buttonType="button"
                    buttonFunction={() => setSelected([])}
                    buttonClasses={"!w-14 !h-8 !text-sm !font-normal"}

                  />
                  <Button
                    buttonLabel={"Ok"}
                    buttonType="button"
                    buttonFunction={() => {
                      if (selected?.length > 0) {
                        onFilter(selected);
                        setOpen(false);
                      } else {
                        onFilter(null);
                        setOpen(false);
                      }
                    }}
                    buttonClasses={"!w-14 !h-8 !text-sm !font-normal"}
                  />
                </div>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </>
  );
};

export default TableFilter;
