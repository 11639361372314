import React, { useEffect, useState } from "react";
import { classNames } from "../../helpers/classNames";
import { toast } from "react-toastify";

const SingleImageUpload = ({ size = "sm", accept = [], label, labelClasses, image, setImage = () => {} }) => {
  const [inputImage, setInputImage] = useState("");
  useEffect(() => {
    setInputImage(image);
  }, [image]);

  useEffect(() => {
    if (inputImage !== "") {
      setImage(inputImage);
    }
  }, [inputImage, setImage]);

  const handleInputChange = (event) => {
    const input = event.target;
    if (accept && Array.isArray(accept) && accept.length > 0) {
      let type = input.files[0]?.type.split("/")[1];
      if (accept.includes(type)) {
        setInputImage(input.files[0]);
      } else {
        toast.info(`Image only accept ${accept.join(", ")}`);
      }
    } else {
      setInputImage(input.files[0]);
    }
  };

  const handleRemoveChange = () => {
    setInputImage(null);
  };

  const boxSize = {
    sm: "w-16 w-16",
    md: "w-32 w-32",
    lg: "w-60 h-60",
  };
  const closeSize = {
    sm: "w-5 text-sm",
    md: "w-6 text-base",
    lg: "w-8 text-lg",
  };
  const uploaderIconSize = {
    sm: "text-xl",
    md: "text-2xl",
    lg: "text-3xl",
  };
  const uploaderTextSize = {
    sm: "text-[10px]",
    md: "text-xs",
    lg: "text-sm",
  };

  return (
    <div className="relative space-y-1 w-full">
      {label && (
        <div className={classNames("text-sm font-semibold text-opplio-gray tracking-none", labelClasses)}>{label}</div>
      )}
      <div className="flex flex-wrap gap-4 pt-2">
        {inputImage ? (
          <div className="relative">
            <div
              className={classNames(
                "relative aspect-square overflow-hidden border border-slate-200 bg-white rounded-md",
                boxSize[size]
              )}
            >
              <img
                src={inputImage instanceof File ? URL.createObjectURL(inputImage) : inputImage}
                alt=""
                className="object-cover w-full h-full"
              />
            </div>
            <button
              type="button"
              className={classNames(
                "aspect-square rounded-full bg-white shadow absolute -top-2.5 -right-2.5 text-red-500",
                closeSize[size]
              )}
              onClick={handleRemoveChange}
            >
              <i className="fa-regular fa-fw fa-xmark"></i>
            </button>
          </div>
        ) : (
          <div className="relative">
            <div
              className={classNames(
                "relative aspect-square overflow-hidden border-2 border-dashed border-slate-200 text-slate-500 bg-slate-50 rounded-md flex items-center justify-center flex-col gap-1 leading-none",
                boxSize[size],
                uploaderIconSize[size]
              )}
            >
              <input
                type="file"
                className="absolute top-0 right-0 opacity-0 w-[1000px] h-full z-[1] cursor-pointer"
                onChange={handleInputChange}
              />
              <i class="fa-light fa-camera"></i>
              <div className={classNames("font-semibold uppercase tracking-wider", uploaderTextSize[size])}>
                {size === "sm" ? "Image" : "Upload Image"}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SingleImageUpload;
