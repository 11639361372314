import React, { useEffect, useRef, useState } from "react";
import { classNames } from "../../helpers/classNames";
import { toast } from "react-toastify";

const InputFile = ({
  label,
  labelClasses,
  inputClasses,
  inputPlaceholder,
  inputButtonPlaceholder,
  inputFilename,
  inputValue,
  inputName,
  errorType,
  errorText,
  isDisabled,
  accept = [],
  image,
  setImage = () => {},
  ...props
}) => {
  const inputRef = useRef();
  const [value, setValue] = useState(inputValue ? inputValue : "");
  const [fileName, setFileName] = useState(inputFilename ? inputFilename : "");

  const [inputImage, setInputImage] = useState("");
  useEffect(() => {
    setInputImage(image);
  }, [image]);

  useEffect(() => {
    if (inputImage !== "") {
      setImage(inputImage);
    }
  }, [inputImage, setImage]);

  const handleChange = (event) => {
    const input = event.target;
    if (accept && Array.isArray(accept) && accept.length > 0) {
      let type = input.files[0]?.type.split("/")[1];
      if (accept.includes(type)) {
        setInputImage(input.files[0]);
        setValue(input.value);
        setFileName(input.files[0].name);
      } else {
        toast.info(`File only accept ${accept.join(", ")}`);
      }
    } else {
      setValue(event.target.value);
      setFileName(input.files[0].name);
    }
  };

  // const handleChange = (e) => {
  //   setValue(e.target.value);
  //   setFileName(e.currentTarget.files[0].name);
  // };

  const handleRemove = () => {
    setValue("");
    setFileName("");
    setInputImage(null);
    inputRef.current.value = "";
  };

  return (
    <>
      <div className="relative">
        {label && (
          <div
            className={classNames(
              "text-sm font-medium text-slate-950 mb-1",
              labelClasses
            )}
          >
            {label}
          </div>
        )}
        {isDisabled ? (
          <div className="relative flex w-full border border-opplio-border rounded-md h-10 bg-white overflow-hidden">
            <input
              type="file"
              className="absolute top-0 right-0 w-[200%] h-full z-[1] cursor-pointer opacity-0"
              value={inputValue}
              disabled
            />
            <div className="flex items-center justify-center text-xs font-medium px-2 flex-shrink-0 bg-opplio-silver/50 border-r border-opplio-border z-[2]">
              {inputButtonPlaceholder}
            </div>
            <div className="w-full flex-shrink flex-grow-0 flex items-center px-2 truncate">
              <div className="w-full text-sm truncate">
                {inputFilename
                  ? inputFilename
                  : fileName
                  ? fileName
                  : inputPlaceholder}
              </div>
            </div>
          </div>
        ) : (
          <div className="relative flex w-full border border-opplio-border rounded-md h-10 bg-white overflow-hidden">
            <input
              ref={inputRef}
              type="file"
              className="absolute top-0 right-0 w-[200%] h-full z-[1] cursor-pointer opacity-0"
              value={inputValue}
              onChange={handleChange}
            />
            <div className="flex items-center justify-center text-xs font-medium px-2 flex-shrink-0 bg-opplio-silver/50 border-r border-opplio-border z-[2]">
              {inputButtonPlaceholder}
            </div>
            <div className="w-full flex-shrink flex-grow-0 flex items-center px-2 truncate">
              <div className="w-full text-sm truncate">
                {inputFilename
                  ? inputFilename
                  : fileName
                  ? fileName
                  : inputPlaceholder}
              </div>
            </div>
            {value && fileName && (
              <button
                type="button"
                className="relative w-8 h-full flex items-center justify-center flex-shrink-0 z-[3] text-base text-opplio-grayB hover:text-opplio-red"
                onClick={handleRemove}
              >
                <i className="fa-light fa-fw fa-xmark"></i>
              </button>
            )}
          </div>
        )}
        {errorType && errorType === "danger" && (
          <div className="text-xs text-red-600 mt-1">
            <i className="fa-regular fa-fw fa-square-exclamation text-sm mr-1"></i>
            {errorText}
          </div>
        )}
        {errorType && errorType === "warning" && (
          <div className="text-xs text-amber-600 mt-1">
            <i className="fa-regular fa-fw fa-triangle-exclamation text-sm mr-1"></i>
            {errorText}
          </div>
        )}
        {errorType && errorType === "success" && (
          <div className="text-xs text-green-600 mt-1">
            <i className="fa-regular fa-fw fa-circle-check text-sm mr-1"></i>
            {errorText}
          </div>
        )}
        {errorType && errorType === "info" && (
          <div className="text-xs text-sky-600 mt-1">
            <i className="fa-regular fa-fw fa-circle-info text-sm mr-1"></i>
            {errorText}
          </div>
        )}
      </div>
    </>
  );
};

export default InputFile;
