import React, { useState } from "react";
import Image from "../components/elements/Image";
import QueryImage from "../assets/images/query.png";
import { classNames } from "../helpers/classNames";
import InputFile from "../components/form/InputFile";
import Button from "../components/form/Button";
import Editor from "../components/elements/Editor";
import useForm from "../hooks/useForm";
import { formValidate } from "../helpers/formValidate";
import { toast } from "react-toastify";
import { Validation } from "../helpers/Validation";
import { addQuery } from "../services/queryService";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const Query = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");
  const [queryValue, setQueryValue] = useState();
  const [file, setFile] = useState();
  const [loaded, setLoaded] = useState();
  const [isSuccess, setIsSucess] = useState({ message: false, view: true });
  const [list, setList] = useState({
    loading: true,
    data: {
      message: "Sorry! Quote not found.",
      icon: "fa-solid fa-circle-xmark",
      color: "text-error",
    },
  });
  const validation = {
    description: { required: true, message: "Please enter your query" },
  };
  const { values, handleChange, handleSubmit, errors, setFieldsValue } =
    useForm({});

  const onSubmit = async (values) => {
    try {
      setLoaded(true);
      if (!id) {
        toast.info("Invalid Scn Id");
        setLoaded(false);
        return;
      }
      if (!queryValue) {
        toast.error("Query cannot be empty or contain only spaces.");
        setLoaded(false);
        return;
      }
      const res = await addQuery({
        scnId: id,
        queryText: queryValue,
        scnQueryDoc: file,
      });
      if (res?.data.status === 400) {
        setIsSucess({ message: true, view: false });
        setList({
          loading: false,
          data: {
            message: res?.data?.message || res?.data?.errors,
            icon: "fa-solid fa-circle-xmark",
            color: "text-error",
          },
        });
      } else if (res?.status === 200 || res?.status === 201) {
        setList({
          loading: false,
          data: {
            message: res?.message,
            icon: "fas fa-badge-check",
            color: "text-shamrock-700",
          },
        });
        setIsSucess({ message: true, view: false });
      } else if (res?.data?.status === 500) {
        setIsSucess({ message: true, view: false });
        setList({
          loading: false,
          data: {
            message: res?.data?.message || res?.data?.error,
            icon: "fa-solid fa-circle-xmark",
            color: "text-error",
          },
        });
      } else {
        setIsSucess({ message: true, view: false });
        setList({
          loading: false,
          data: {
            message:
              res?.data?.message ||
              res?.data?.error ||
              "Something went wrong !!!",
            icon: "fa-solid fa-circle-xmark",
            color: "text-error",
          },
        });
      }
    } catch (err) {
      console.error(err);
      setList({
        loading: false,
        data: {
          message: "Something went wrong !!!",
          icon: "fa-solid fa-circle-xmark",
          color: "text-error",
        },
      });
    } finally {
      setLoaded(false);
    }
  };

  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-10 items-center">
        <div className="sticky hidden md:block">
          <div className="w-96 md:w-80 lg:w-96 h-96 md:h-80 lg:h-96 overflow-hidden mx-auto">
            <Image
              src={QueryImage}
              alt={""}
              width={"100%"}
              height={"100%"}
              effect={"blur"}
              classes={"object-cover object-bottom lg:object-center"}
            />
          </div>
        </div>
        {isSuccess?.view ? (
          <>
            <form onSubmit={(event) => handleSubmit(event, onSubmit)}>
              <div className="relative px-8 sm:px-2 md:px-8 lg:px-10 py-10 md:py-16 bg-slate-50">
                <div className="space-y-3">
                  <div className="relative">
                    <h2
                      className={classNames(
                        "text-3xl xl:text-4xl 2xl:text-5xl text-primaryAlt font-bold relative mb-10",
                        "before:content-[''] before:absolute before:-bottom-4 before:left-16 before:-translate-x-1/2 before:w-32 before:border-b-2 before:border-primaryAltFade/25",
                        "after:content-[''] after:absolute after:bottom-[-21px] after:left-16 after:-translate-x-1/2 after:border-[6px] after:border-secondaryColor after:rotate-45"
                      )}
                    >
                      Send A Query
                      <p className="text-slate-700 text-sm relative mt-2 font-normal">
                      Sending a query below will prevent the Payment Milestone
                      transfer from the holding account to your service provider
                      until resolution. This should only be used if you disagree
                      with the Completion Notice. If you have a general query,
                      you should contact your service provider directly.
                    </p>
                    </h2>
             
                  </div>
                  <div className="quillcss">
                    <Editor
                      value={queryValue}
                      onChange={(e) => setQueryValue(e)}
                      placeholder={"Enter payment query details"}
                    />
                  </div>
                  <InputFile
                    label={"Upload Your Attachment"}
                    labelClasses={"!text-slate-400 !text-[13px] !mb-3"}
                    inputButtonPlaceholder={"Choose File"}
                    inputPlaceholder={"Browse for file"}
                    image={file}
                    setImage={setFile}
                    accept={["jpg", "png", "jpeg", "pdf"]}
                  />
                  <Button
                    buttonClasses={"!bg-primary !text-white !mt-5 !px-10"}
                    buttonLabel={"Send"}
                    buttonEffect={"filled"}
                    buttonType={"submit"}
                    isDisable={loaded}
                    isLoaderDisabled={!loaded}
                  />
                </div>
              </div>
            </form>
          </>
        ) : (
          <>
            <div className="w-80 h-80 rounded-lg shadow-lg flex flex-col items-center justify-center bg-white m-auto">
              <div className="flex items-center justify-center">
                <i
                  className={`text-9xl ${list?.data?.color} ${list?.data?.icon}`}
                ></i>
              </div>
              <p className="mt-6 text-md p-5 text-primary font-bold">
                {list?.data ? list?.data?.message : ""}
                {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam sollicitudin
                 */}
                {/* This has now been referred to the dispute resolution procedure. */}
              </p>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Query;
