import React from "react";
import Image from "../elements/Image";
import Button from "../form/Button";
import BannerImage from "../../assets/images/banner-image-2.png";

const MainBanner = ({ data }) => {
  return (
    <>
      <section
        className="relative overflow-hidden w-full z-0 h-auto xl:h-screen flex items-center bg-white mt-5 xl:mt-0"
        id="banner"
      >
        <div className="relative z-[1] w-full px-4 sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-9xl mx-auto flex items-center">
          <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-20">
            <div className="w-full my-auto">
              <div className="relative w-full max-w-2xl space-y-3 md:space-y-8">
                <div className="space-y-4 banner-title">
                  <div className="text-xl uppercase font-semibold text-secondaryColor">
                    {data.title}
                  </div>
                  <h1
                    className="text-xl lg:text-2xl xl:text-5xl 2xl:text-6xl text-slate-700 tracking-tight !leading-tight block title"
                    dangerouslySetInnerHTML={{ __html: data?.description }}
                  ></h1>
                </div>
                <div className="text-sm xl:text-lg text-slate-500 font-light !leading-relaxed">
                  {data.subTitle}
                </div>
                <div className="flex items-center !mt-10">
                  <Button
                    buttonClasses={"!px-5"}
                    buttonHasLink={false}
                    //buttonFunction={data.actionLink}
                    buttonLink={"/"}
                    buttonLabel={"Get Started"}
                    buttonLabelClasses={"uppercase font-semibold"}
                    buttonEffect={"filled"}
                  />
                </div>
              </div>
            </div>
            <div className="w-full">
              <div className="relative w-full max-w-2xl mx-auto">
                <Image
                  src={data?.imageUrl}
                  // src={BannerImage}
                  alt={""}
                  width={"100%"}
                  height={"100%"}
                  effect={"blur"}
                  classes={"object-cover"}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MainBanner;
