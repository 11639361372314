import { apiRequest } from "../util/apiUtils";

export const getServiceList = async (params) => {
  try {
    const res = await apiRequest("get", "/service/list", {
      queries: params,
    });
    return res?.data;
  } catch (error) {
    return error;
  }
};
export const changeServiceStatus = async (payload = {}) => {
  try {
    const res = await apiRequest("post", "service/statusChange", {
      body: payload,
    });
    return res;
  } catch (err) {
    return err;
    throw err;
  }
};


export const addService = async (params) => {
  try {
    const res = await apiRequest(
      params.id ? "post" : "post",
      params.id ? "service/edit" : "service/add",
      {
        body: {...params},
      },
    );
    return res;
  } catch (error) {
    return error;
  }
};

export const deleteService = async (params) => {
  try {
    const res = await apiRequest("get", `service/delete/${params?.id}`, {
      body: {},
    });
    return res?.data;
  } catch (error) {
    return error;
  }
};
