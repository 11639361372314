import React, { useEffect, useState } from "react";
import PageHeader from "../../../shared/PageHeader";
import Button from "../../../components/form/Button";
import Input from "../../../components/form/Input";
import SingleImageUpload from "../../../components/form/SingleImageUpload";
import { formValidate } from "../../../helpers/formValidate";
import useForm from "../../../hooks/useForm";
import { Validation } from "../../../helpers/Validation";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { addUser } from "../../../services/userService";
import TextEditor from "../../../components/form/TextEditor";
import { setTitle } from "../../../helpers/MetaTag";
import TextAreaAutoSize from "../../../components/form/TextareaAutoSize";
import { addFaq } from "../../../services/faqService";

const AddEditFaq = ({
  isOpen,
  setIsOpen = () => {},
  setCount = () => {},
  count,
  setDetailsData = () => {},
  detailsData,
}) => {
  setTitle("Service Flows");
  const { values, handleChange, handleSubmit, errors, setFieldsValue } =
    useForm({});
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(false);
  const [question, setQuestion] = useState();
  const [answer, setAnswer] = useState();

  useEffect(() => {
    if (detailsData?.id) {
      setAnswer(detailsData?.answer);
      setQuestion(detailsData?.question);
    }
  }, [detailsData]);

  
  const onSubmit = async (values) => {
    setLoaded(true);
    try {
      if (!question) {
        toast.error("Please enter question !!!");
        setLoaded(false);
        return;
      }
      if (!answer) {
        toast.error("Please enter answer !!!");
        setLoaded(false);
        return;
      }
      const res = await addFaq({
        id: detailsData && detailsData?.id,
        question: question,
        answer: answer,
      });

      if (res?.data?.status === 200 || res?.data?.status === 201) {
        setIsOpen(false);
        setCount((pre) => pre + 1);
        toast.success(res?.data?.message);
        setDetailsData({});
      } else {
        toast.info(
          res?.data?.message ||
            res?.data?.errors ||
            res?.data?.error ||
            "Some thing went wrong"
        );
        setIsOpen(false);
        setDetailsData({});
      }
      setLoaded(false);
    } catch (err) {
      setLoaded(false);
      // console.error(err);
      toast.error("Somthing went wrong !!!");
    }
  };

  return (
    <div>
      <form onSubmit={(event) => handleSubmit(event, onSubmit)}>
        <div className="py-2 px-6">
          <div className="grid grid-cols-4 gap-4">
            <div className="col-span-4">
              <div className="space-y-3">
                <div className="grid grid-cols-12 gap-4">
                  <div className="relative col-span-12">
                    <TextAreaAutoSize
                      label={"Question"}
                      labelClasses={"!text-base"}
                      inputValue={question}
                      inputName={"description"}
                      onChange={(e) => setQuestion(e.target.value)}
                      inputPlaceholder={"Enter Description"}
                    />
                  </div>
                  <div className="relative col-span-12">
                    <TextEditor
                      label="Answer"
                      value={answer}
                      onChange={(e) => setAnswer(e)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-8 pt-4 border-t border-slate-200">
            <div className="flex items-center space-x-3 justify-end">
              <Button
                buttonHasLink={false}
                buttonType={"button"}
                buttonIcon={"fa-regular fa-xmark"}
                buttonIconPosition={"left"}
                buttonLabel={"Cancel"}
                buttonClasses={
                  "!bg-white !text-primary !border-primary !border-2"
                }
                buttonFunction={() => {
                  setIsOpen(false);
                  setDetailsData({});
                }}
              />
              <Button
                buttonHasLink={false}
                buttonType={"submit"}
                buttonIcon={"fa-light fa-floppy-disk"}
                buttonIconPosition={"left"}
                buttonClasses={"!bg-primary !text-org-silver"}
                buttonLabel={"Save"}
                isDisable={loaded}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddEditFaq;
