import { apiRequest } from "../util/apiUtils";

export const getEmailTemplateList = async (params) => {
  try {
    const res = await apiRequest("get", "/emailTemplate/list", {
      queries: params,
    });
    return res?.data;
  } catch (error) {
    return error;
  }
};

export const changeFaqStatus = async (payload = {}) => {
  try {
    const res = await apiRequest("patch", "user/status-admin-user", {
      body: payload,
    });
    return res;
  } catch (err) {
    return err;
    throw err;
  }
};

export const emailTemplateDetails = async (params) => {
  try {
    const res = await apiRequest("get", `emailTemplate/details/${params?.id}`, {
      queries: params,
    });
    return res?.data;
  } catch (err) {
    throw err;
  }
};

export const editEmail = async (params) => {
  try {
    const res = await apiRequest(
      "post",
      "emailTemplate/edit",
      {
        body: { ...params },
      },
    );
    return res;
  } catch (error) {
    return error;
  }
};
