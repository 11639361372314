import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { SignInService } from "../../services/loginService";
import Logo from "../../assets/images/favicon.png";
import { formValidate } from "../../helpers/formValidate";
import Input from "../../components/form/Input";
import Button from "../../components/form/Button";
import useForm from "../../hooks/useForm";
import Background from "../../assets/images/bg.jpg";

const validation = {
  email: { required: true, message: "Email is required" },
  password: { required: true, message: "Password is required" },
};

const Login = () => {
  const { values, handleChange, handleSubmit, errors, setFieldsValue } =
    useForm({}, validation);
  const backgroundStyle = {
    backgroundColor: "#f1f5f9",
    // backgroundImage:`url(${Background})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  };
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState({
    color: "rose-500",
    message: null,
  });
  const [loaded, setLoaded] = useState(true);

  const onSubmit = async (values) => {
    setLoaded(false);
    try {
      const res = await SignInService({
        email: values?.email,
        password: values?.password,
      });
      if (res?.data?.status === 200) {
        setErrorMessage({ color: "green-500", message: res?.data?.message });
        localStorage.setItem("auth_token", res?.data?.accessToken);
        localStorage.setItem("refreshToken", res?.data?.refreshToken);
        toast.info(res.data.message);
        setTimeout(() => {
          navigate("/admin/admin-list");
        }, 1000);
      } else if (res?.data?.status === 400) {
        setErrorMessage({
          color: "rose-500",
          message: res?.data?.message || res?.data?.errors,
        });
        toast.info(res?.data?.message || res?.data?.errors);
      } else {
        setErrorMessage({
          color: "rose-500",
          message: res?.data?.message || res?.data?.errors,
        });
        toast.info(res?.data?.message || res?.data?.errors);
      }
    } catch (error) {
      console.error("Error signing in:", error);
      toast.info("Something went wrong!!!");
      setErrorMessage({
        color: "rose-500",
        message:
          error?.response?.data?.message ||
          error.message ||
          "Something went wrong!!!",
      });
      toast.error(error?.response?.data?.message || error.message);
    } finally {
      setLoaded(true);
    }
  };

  return (
    <div
      className="w-full min-h-screen flex items-center justify-center py-3 px-5"
      style={backgroundStyle}
    >
      <div className="w-full max-w-md xl:max-w-md bg-white py-10 rounded-2xl">
        <div className="flex min-h-full flex-1 flex-col justify-center px-2 py-4 lg:px-2">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <img
              className="mx-auto h-20 w-auto"
              src={Logo}
              alt="Your Company"
            />
            <h2 className="mt-1 text-center text-xl font-bold leading-9 tracking-tight text-slate-600">
              Please Use Your Credentials to Login
            </h2>
            <h4
              className={`mt-1 text-center text-md font-bold leading-9 tracking-tight text-${errorMessage?.color}`}
            >
              {errorMessage?.message}
            </h4>
          </div>

          <div className="mt-2 sm:mx-auto sm:w-full sm:max-w-sm">
            <form onSubmit={(event) => handleSubmit(event, onSubmit)}>
              <div className="mt-2">
                <Input
                  label={"Email"}
                  labelClasses={"!text-xs"}
                  inputType={"text"}
                  inputPlaceholder={"Enter your email"}
                  value={values?.email}
                  inputName={"email"}
                  onChange={handleChange}
                  {...formValidate(errors, "email")}
                />
              </div>

              <div>
                <div className="mt-2">
                  <Input
                    label={"Password"}
                    labelClasses={"!text-xs"}
                    inputType={"password"}
                    inputPlaceholder={"Enter your password"}
                    value={values?.password}
                    inputName={"password"}
                    onChange={handleChange}
                    {...formValidate(errors, "password")}
                  />
                </div>
              </div>

              <div className="mt-2">
                <Button
                  buttonClasses={
                    "w-full text-white hover:bg-secondaryColor focus-visible:outline justify-center rounded-md"
                  }
                  buttonHasLink={false}
                  buttonType={"submit"}
                  buttonLabel={"Log in"}
                  buttonIconPosition={"left"}
                  buttonIcon={"fa-light fa-right-to-bracket"}
                />
              </div>
              {/* <div className="mt-4 text-end">
                <div className="text-sm">
                  <Link
                    to="/forgotPassword"
                    className="font-semibold text-victoria hover:text-"
                  >
                    Forgot password?
                  </Link>
                </div>
              </div> */}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
