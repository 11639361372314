import React, { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import ScrollSpy from "react-ui-scrollspy";

import DefaultHeader from "../shared/DefaultHeader";
import DefaultFooter from "../shared/DefaultFooter";

import Logo from "../assets/images/logo.png";
import PlayStore from "../assets/images/play-store.png";
import AppStore from "../assets/images/app-store.png";
import { apiService } from "../services/api";
import { loadSiteSettingsDetails } from "../services/settingService";

const DefaultLayout = ({ isMenuData }) => {
  const [settings, setSettings] = useState({});

  const menuData = {
    logo: Logo,
    menu: [
      { _id: 1, name: "Home", link: "banner" },
      { _id: 2, name: "About Us", link: "about" },
      { _id: 3, name: "FAQ's", link: "faq" },
      { _id: 4, name: "Contact Us", link: "contact" },
    ],
    appDownload: {
      menu: [
        { _id: 1, name: "Play Store", link: settings?.googlePlay || " ", image: PlayStore },
        { _id: 2, name: "App Store", link: settings?.appStore || "", image: AppStore },
      ],
    },
  };

  const footerData = {
    logo: Logo,
    footerMenu: {
      title: "Quick Links",
      menu: [
        { _id: 1, name: "Home", link: "#banner" },
        { _id: 2, name: "About Us", link: "#about" },
        { _id: 4, name: "FAQ's", link: "#faq" },
        { _id: 5, name: "Contact Us", link: "#contact" },
      ],
    },
    contact: {
      title: "Contact Us",
      subdata: [
        {
          _id: 1,
          label: "Email",
          data: settings?.email || "",
          icon: "fa-envelope",
          type: "mailto",
        },
        {
          _id: 2,
          label: "Contact No.",
          data: settings?.phone || "",
          icon: "fa-phone-volume",
          type: "tel",
        },
        {
          _id: 3,
          label: "Address",
          data: settings?.address || "",
          icon: "fa-location-dot",
          type: "address",
        },
      ],
    },
    footerDownload: {
      title: "App Download",
      menu: [
        {
          _id: 1,
          name: "Play Store",
          link: settings?.googlePlay || " ",
          image: PlayStore,
        },
        {
          _id: 2,
          name: "App Store",
          link: settings?.appStore || "",
          image: AppStore,
        },
      ],
    },
    socialMenuTitle: "Reach us at",
    socialMenu: [
      {
        _id: 1,
        name: "Facebook",
        link: settings?.faceBook || "",
        icon: "fa-facebook-f",
      },
      {
        _id: 2,
        name: "Instagram",
        link: settings?.instagram || "",
        icon: "fa-instagram",
      },
      {
        _id: 3,
        name: "Twitter",
        link: settings?.twitter || "",
        icon: "fa-x-twitter",
      },
    ],
    copyright:
      settings?.copyright ||
      "Copyright © 2024 ServiceFlows | All Rights Reserved",
  };

  const pricing = [
    {
      _id: 1,
      name: "Basic",
      amount: 250,
      terms: "mo",
      tag: "",
      click: () => {},
      features: [
        { name: "Personal Access", availability: true },
        { name: "Unlimited overview", availability: true },
        { name: "Limited task creation and edit", availability: true },
        { name: "Direct messeging to the coach", availability: true },
        { name: "Upto 5 areas of interest", availability: true },
        { name: "Self-Learning system", availability: false },
        { name: "Google task integration", availability: false },
      ],
    },
    {
      _id: 2,
      name: "Standard",
      amount: 500,
      terms: "mo",
      tag: "Recomended",
      click: () => {},
      features: [
        { name: "Personal Access", availability: true },
        { name: "Unlimited overview", availability: true },
        { name: "Unlimited task creation and edit", availability: true },
        { name: "Direct messeging to the coach", availability: true },
        { name: "Upto 10 areas of interest", availability: true },
        { name: "Self-Learning system", availability: true },
        { name: "Google task integration", availability: true },
      ],
    },
    {
      _id: 3,
      name: "Premium",
      amount: 750,
      terms: "mo",
      tag: "",
      click: () => {},
      features: [
        { name: "Personal Access", availability: true },
        { name: "Unlimited overview", availability: true },
        { name: "Unlimited task creation and edit", availability: true },
        { name: "Direct messeging to the coach", availability: true },
        { name: "All areas of interest", availability: true },
        { name: "Self-Learning system", availability: true },
        { name: "Google task integration", availability: true },
      ],
    },
  ];

  useEffect(() => {
    loadSiteSettingsDetails().then((res) => {
      if (res?.data?.status === 200) {
        setSettings(res.data.docs);
      }
    });
  }, []);

  return (
    <>
      <DefaultHeader data={menuData} isMenuData={isMenuData} />
      <ScrollSpy
        scrollThrottle={100}
        useBoxMethod={false}
        updateHistoryStack={false}
        activeClass="active"
      >
        <Outlet />
      </ScrollSpy>
      <DefaultFooter data={footerData} settings={settings} />
    </>
  );
};

export default DefaultLayout;
