import React from "react";
import { classNames } from "../../helpers/classNames";
import { Link } from "react-router-dom";
import Image from "../elements/Image";

const ContactUs = ({ data }) => {
  return (
    <>
      <section
        className="relative overflow-hidden w-full py-0 xl:py-12 md:py-8 bg-slate-50 z-0"
        id="contact"
      >
        <div className="relative w-full px-4 sm:px-0 sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl 3xl:max-w-9xl mx-auto py-8">
          <div className="space-y-10">
            <div className="w-full max-w-7xl mx-auto text-center">
              <h2
                className={classNames(
                  "text-3xl xl:text-4xl 2xl:text-5xl text-primaryAlt font-bold relative mb-10",
                  "before:content-[''] before:absolute before:-bottom-4 before:left-1/2 before:-translate-x-1/2 before:w-32 before:border-b-2 before:border-primaryAltFade/25",
                  "after:content-[''] after:absolute after:bottom-[-21px] after:left-1/2 after:-translate-x-1/2 after:border-[6px] after:border-secondaryColor after:rotate-45"
                )}
                dangerouslySetInnerHTML={{ __html: data?.title || "" }}
              ></h2>
              <div
                className="text-base xl:text-sm text-slate-500 space-y-4 font-light"
                dangerouslySetInnerHTML={{ __html: data?.subTitle || "" }}
              ></div>
            </div>
            <div className="grid grid-cols-1 gap-8 lg:gap-12 mt-10 lg:grid-cols-2">
              <div className="relative">
                {data.contact?.length > 0 && (
                  <div className="grid gap-12 grid-cols-2">
                    {data.contact?.map((item, index) => (
                      <div
                        className="space-y-1 group duration-300 transition-all"
                        key={index}
                      >
                        {item.icon && (
                          <div className="w-14 h-14 text-2xl flex items-center justify-center text-white rounded-full bg-primary mb-4 group-hover:bg-secondaryColor duration-300 transition-all">
                            <i className={classNames("fa-fw", item.icon)}></i>
                          </div>
                        )}
                        {item.name && (
                          <h4 className="text-sm font-bold text-black group-hover:text-secondaryColor duration-300 transition-all">
                            {item.name}
                          </h4>
                        )}
                        {item.description && (
                          <div className="text-[13px] text-slate-400">
                            {item.description}
                          </div>
                        )}
                        {item.type === "email" ||
                        item.type === "phone" ||
                        item.type === "link" ? (
                          <a
                            href={
                              item.type === "email"
                                ? "mailto:" + item.data
                                : item.type === "phone"
                                ? "tel:" + item.data
                                : item.type === "link"
                                ? item.link
                                : "/"
                            }
                            target={item.type === "link" ? "_blank" : "_self"}
                            rel={item.type === "link" ? "noreferrer" : ""}
                            className="text-sm text-slate-500 font-semibold"
                          >
                            {item.data}
                          </a>
                        ) : (
                          <div className="text-sm text-slate-500 font-semibold">
                            {item.data}
                          </div>
                        )}
                      </div>
                    ))}
                    {data.socialMenu && (
                      <>
                        {data.socialMenu?.length > 0 && (
                          <div className="space-y-1">
                            {data.socialMenuTitle && (
                              <h4 className="text-sm font-bold text-black mb-3">
                                {data.socialMenuTitle}
                              </h4>
                            )}
                            <div className="space-y-1">
                              {data.socialMenu?.map((item) => (
                                <a
                                  key={item._id}
                                  href={item.link}
                                  target="_blank"
                                  rel="noreferrer"
                                  className="flex items-center py-1 space-x-4 group duration-300 transition-all"
                                >
                                  <div className="flex items-center justify-center w-8 h-8 bg-primaryAlt rounded-md text-white transition-all duration-300 group-hover:bg-secondaryColor">
                                    <i
                                      className={classNames(
                                        "fa-brands fa-fw",
                                        item.icon
                                      )}
                                    ></i>
                                  </div>
                                  <div className="text-sm font-semibold text-black transition-all duration-300 group-hover:text-primary">
                                    {item.name}
                                  </div>
                                </a>
                              ))}
                            </div>
                          </div>
                        )}
                      </>
                    )}
                    {data.appDownload && (
                      <>
                        {data.appDownload?.menu?.length > 0 && (
                          <div className="space-y-1">
                            {data.socialMenuTitle && (
                              <h4 className="text-sm font-bold text-black mb-3">
                                {data.appDownload?.title}
                              </h4>
                            )}
                            <div className="relative space-y-3 sm:space-y-4">
                              {data.appDownload?.menu?.map((item) => (
                                <div className="flex" key={item._id}>
                                  <Link
                                    to={item.link}
                                    rel="noreferrer"
                                    target="_blank"
                                    className="text-white text-xs xl:text-sm transition-all duration-200 hover:text-primary"
                                  >
                                    <div className="flex h-10">
                                      <Image
                                        src={item.image}
                                        alt={item.name}
                                        width={"100%"}
                                        height={"100%"}
                                        effect={"blur"}
                                        classes={
                                          "object-contain lg:object-left"
                                        }
                                      />
                                    </div>
                                  </Link>
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                )}
              </div>
              <div className="p-4 py-6 rounded-3xl bg-white shadow-lg border border-slate-50 md:p-8">
                {data.formTitle && (
                  <h2 className="text-xl font-semibold text-primary mb-4">
                    {data.formTitle}
                  </h2>
                )}
                {data.contactForm && data.contactForm}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactUs;
