import { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const TextEditor = ({
  type = "",
  value = "",
  onChange = () => {},
  label = "",
}) => {
  const Quill = ReactQuill.Quill;
  var Font = Quill.import("formats/font");
  Font.whitelist = [
    "Roboto",
    "Raleway",
    "Montserrat",
    "Lato",
    "Rubik",
    "Inter",
    "Pacifico",
  ];
  Quill.register(Font, true);

  var Size = Quill.import("attributors/style/size");
  Size.whitelist = ["45px", "small", false, "large", "huge"];
  Quill.register(Size, true);

  // Register custom colors
  var Color = Quill.import("attributors/style/color");
  Color.whitelist = [
    "#4936dc",
    "#000000",
    "#e60000",
    "#ff9900",
    "#ffff00",
    "#008a00",
    "#0066cc",
    "#9933ff",
    "#ffffff",
    "#facccc",
    "#ffebcc",
    "#ffffcc",
    "#cce8cc",
    "#cce0f5",
    "#ebd6ff",
    "#bbbbbb",
    "#f06666",
    "#ffc266",
    "#ffff66",
    "#66b966",
    "#66a3e0",
    "#c285ff",
    "#888888",
    "#a10000",
    "#b26b00",
    "#b2b200",
    "#006100",
    "#0047b2",
    "#6b24b2",
    "#444444",
    "#5c0000",
    "#663d00",
    "#666600",
    "#003700",
    "#002966",
    "#3d1466",
  ];
  Quill.register(Color, true);

  const [textValue, setTextValue] = useState(value);

  useEffect(() => {
    if (value) {
      setTextValue(value);
    }
  }, [value, type]);

  const modules = {
    toolbar: [
      [{ font: Font.whitelist }],
      [{ size: Size.whitelist }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike"],
      // [{ color: [] },
      // //  { background: [] }
      // ],
      [{ color: Color.whitelist }],
      // [{ script: "sub" }, { script: "super" }],
      // ["blockquote", "code-block"],
      // [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
      // ["link", "image", "video"],
      ["clean"],
    ],
  };

  return (
    <div>
      {label && <label className="block mb-2 text-slate-500">{label}</label>}
      <ReactQuill
        className="bg-white"
        theme="snow"
        value={textValue}
        onChange={(val) => {
          setTextValue(val);
          onChange(val);
        }}
        placeholder={"Write something awesome..."}
        modules={modules}
      />
    </div>
  );
};

export default TextEditor;
