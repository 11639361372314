import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { apiRequest } from "../util/apiUtils";

const ProtectedRoute = (props) => {
  const navigate = useNavigate();
  const [isLoader, setIsLoader] = useState(true);
  const [auth, setAuth] = useState(false);
  let token_ = localStorage.getItem("auth_token");

  useEffect(() => {
    const checkData = async () => {
      try {
        const res = await apiRequest("post", "authentication/verifyToken", {});
        if (res?.status === 401) {
          localStorage.removeItem("auth_token");
          navigate("/admin");
        } else {
          setAuth(true);
        }
      } catch (error) {
        console.error("Error verifying token:", error.message || error);
        if (error?.data?.status === 401) {
          localStorage.removeItem("auth_token");
          navigate("/admin");
        } else {
          console.error("Unexpected error:", error.message || error);
          localStorage.removeItem("auth_token");
          navigate("/admin");
        }
      } finally {
        setIsLoader(false);
      }
    };

    if (!token_) {
      setIsLoader(false);
      navigate("/admin");
    } else {
      checkData();
    }
  }, [token_, navigate]);

  // if (isLoader) {
  //   return <div>Loading...</div>;
  // }

  return <>{auth && props.children}</>;
};

export default ProtectedRoute;
