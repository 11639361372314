import React from 'react';
import { classNames } from "../../helpers/classNames";

const Box = ({ data }) => {
  return (
    <div className="group">
      <div className="bg-[#e8e8ff] shadow-md rounded-xl p-6 space-y-2 group-hover:bg-primary transition-all duration-500 h-48 lg:h-52">
        {data?.icon && (
          <div className="bg-primary rounded-full h-14 w-14 flex items-center justify-center text-white text-2xl group-hover:bg-secondaryColor transition-all duration-500">
            <i className={classNames("fa-fw", data?.icon)}></i>
          </div>
        )}
        {data?.name && (<h2 className="text-xl lg:text-lg xl:text-xl font-bold capitalize group-hover:text-white transition-all duration-500">{data?.name}</h2>)}
        {data?.description && (<div className="text-gray-700 group-hover:text-white transition-all duration-500 text-base lg:text-sm xl:text-base">{data?.description}</div>)}
      </div>
    </div>
  );
};

export default Box;