import { apiRequest } from "../util/apiUtils";

export const getUserList = async (params) => {
  try {
    const res = await apiRequest("get", "/user/list", {
      queries: params,
    });
    return res?.data;
  } catch (error) {
    return error;
  }
};
export const changeUserStatus = async (payload = {}) => {
  try {
    const res = await apiRequest("post", "user/statusChange", {
      body: payload,
    });
    return res;
  } catch (err) {
    return err;
    throw err;
  }
};


export const addUser = async (params) => {
  try {
    const res = await apiRequest(
      params.id ? "post" : "post",
      params.id ? "user/userEdit" : "/user/userAdd",
      {
        body: {...params},
      },
      "multipart/form-data"
    );
    return res;
  } catch (error) {
    return error;
  }
};

export const userDetails = async (params) => {
  try {
    const res = await apiRequest("get", `user/userDetails/${params?.id}`, {
      queries: params,
    });
    return res?.data;
  } catch (err) {
    throw err;
  }
};
export const deleteUser = async (params) => {
  try {
    const res = await apiRequest("get", `user/userDelete/${params?.id}`, {
      body: {},
    });
    return res?.data;
  } catch (error) {
    return error;
  }
};

export const userProfile = async (params) => {
  try {
    const res = await apiRequest("get", `/user/profile`, {
      queries: params,
    });
    return res?.data;
  } catch (err) {
    return err;
  }
};
