import { apiRequest } from "../util/apiUtils";

export const getHomePageData = async (params) => {
  try {
    const res = await apiRequest(
      "get",
      `/page/sectionDetails/${params?.slug}`,
      {}
    );

    return res.data;
  } catch (err) {
    return err?.response;
  }
};

export const getServiceFrontList = async (params) => {
  try {
    const res = await apiRequest("get", "/service/frontList", {
      queries: params,
    });
    return res?.data;
  } catch (error) {
    return error;
  }
};
export const getFaqFrontList = async (params) => {
  try {
    const res = await apiRequest("get", "/faq/frontList", {
      queries: params,
    });
    return res?.data;
  } catch (error) {
    return error;
  }
};

export const addContactQuery = async (params) => {
  try {
    const res = await apiRequest("post", "contact/submitQuery", {
      body: { ...params },
    });
    return res;
  } catch (error) {
    return error;
  }
};
