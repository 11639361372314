import React, { useRef, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { Link } from "react-router-dom";
import { bottom } from "@popperjs/core";
import { usePopper } from "react-popper";
import { initialsValue } from "../helpers";
import Indentification from "../components/elements/Indentification";
import { classNames } from "../helpers/classNames";

const UserDropdown = ({ data,userData, xPlacement, isMenuOpen, ...props }) => {
  const popperElRef = useRef(null);
  const [targetElement, setTargetElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);

  const placements = {
    left: "bottom-start",
    right: "bottom-end",
    "": bottom,
  };

  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement: placements[xPlacement],
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 8],
        },
      },
    ],
  });


  return (
    <>
      <Menu as="div">
        {({ open }) => (
          <>
            <Menu.Button
              ref={setTargetElement}
              className="flex items-center transition duration-500"
            >
              <div className="relative w-full bg-slate-200  flex items-center justify-center focus:ring-0 transition-all duration-200 gap-3 border border-slate-200 rounded-full px-2 py-1">
                {!isMenuOpen ? (
                  <>
                    <div
                      className={
                        "relative w-10 h-10 overflow-hidden rounded-full border border-slate-300"
                      }
                    >
                      <Indentification
                        initial={initialsValue(userData?.name)}
                        image={userData?.image}
                        // image={"https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg"}
                        alt={userData?.name}
                        fill={false}
                        className={"w-full h-full object-cover"}
                      />
                    </div>
                    <div className="relative flex items-center gap-1">
                      <div className="font-fa-medium text-slate-800  text-base">
                        {userData?.name}
                      </div>
                      <div className="text-xs text-slate-400">
                        <i
                          className={
                            "fa-light fa-fw " +
                            (open ? "fa-chevron-up" : "fa-chevron-down")
                          }
                        ></i>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      className={
                        "relative w-10 h-10 overflow-hidden rounded-full border border-slate-300"
                      }
                    >
                      <Indentification
                        initial={initialsValue(userData?.name)}
                        image={userData?.image}
                        // image={"https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885_1280.jpg"}
                        alt={userData?.name}
                        fill={false}
                        className={"w-full h-full object-cover"}
                      />
                    </div>
                  </>
                )}

                {/* <div className="text-sm font-semibold text-slate-500 mx-2">{data.name}</div>
                <div className="text-xs text-slate-400">
                  <i className={"fa-light fa-fw " + (open?'fa-chevron-up':'fa-chevron-down')}></i>
                </div> */}
              </div>
            </Menu.Button>
            <Transition
              show={open}
              leave="transition duration-500"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              className="z-50 transition duration-500"
              beforeEnter={() => setPopperElement(popperElRef.current)}
              afterLeave={() => setPopperElement(null)}
              ref={popperElRef}
              style={styles.popper}
              {...attributes.popper}
            >
              <Menu.Items className="w-44 origin-top-right bg-white border-0 divide-y divide-slate-100 rounded-md shadow-md ring-0 transition-all duration-200">
                <div className="py-1 divide-y divide-slate-100 overflow-auto scroll-smooth scrollbar transition-all duration-200">
                  {data.map((item) => (
                    <Menu.Item key={item._id}>
                      {item.link ? (
                        <Link
                          to={item.link}
                          className={classNames(
                            "flex items-center py-2 px-3 gap-3 text-slate-600 font-regular transition-all duration-200",
                            item.standout
                              ? "text-carnation-500 hover:bg-carnation-100"
                              : "text-slate-500 hover:bg-slate-100"
                          )}
                        >
                          <div className="w-5 h-5 flex items-center justify-center text-">
                            <i className={"fa-regular fa-fw " + item.icon}></i>
                          </div>
                          <div className="text-md capitalize">{item.label}</div>
                        </Link>
                      ) : (
                        <button
                          type="button"
                          className={classNames(
                            "w-full flex items-center py-2 px-3 gap-3 text-slate-600 font-regular transition-all duration-200",
                            item.standout
                              ? "text-carnation-500 hover:bg-carnation-100"
                              : "text-slate-500 hover:bg-slate-100"
                          )}
                          onClick={item.onclick}
                        >
                          <div className="w-5 h-5 flex items-center justify-center text-">
                            <i className={"fa-regular fa-fw " + item.icon}></i>
                          </div>
                          <div className="text-md capitalize">{item.label}</div>
                        </button>
                      )}
                    </Menu.Item>
                  ))}
                </div>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </>
  );
};

export default UserDropdown;
