import React, { useCallback, useEffect, useRef, useState } from "react";
import PageHeader from "../../../shared/PageHeader";
import Button from "../../../components/form/Button";
import Input from "../../../components/form/Input";
import SingleImageUpload from "../../../components/form/SingleImageUpload";
import { formValidate } from "../../../helpers/formValidate";
import useForm from "../../../hooks/useForm";
import { Validation } from "../../../helpers/Validation";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { addUser, userDetails } from "../../../services/userService";
import { setTitle } from "../../../helpers/MetaTag";
import {
  editEmail,
  emailTemplateDetails,
} from "../../../services/emailService";
import TextEditor from "../../../components/form/TextEditor";
import { Editor } from "@tinymce/tinymce-react";

const EditEmailTemplate = () => {
  setTitle("Service Flows");
  const { id } = useParams();
  const validation = {
    fromMail: { required: true, message: "Please enter from mail!!!" },
    fromName: { required: true, message: "Please enter  from name !!!" },
    subject: { required: true, message: "Please enter  subject !!!" },
  };
  const { values, handleChange, handleSubmit, errors, setFieldsValue } =
    useForm({}, validation);
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [companyImage, setCompanyImage] = useState();
  const [detailsData, setDetailsData] = useState({ loading: false, data: {} });
  const [content, setContent] = useState();
  const editorRef = useRef(null);
  const tinymceApiKey = "uemqg8efft8kgaho6qxmnav0yzbr7s6n00wmyfrh5b7gyxvl";

  const getDetails = useCallback(() => {
    if (id) {
      setDetailsData({ loading: true, data: {} });
      emailTemplateDetails({ id }).then((res) => {
        if (res?.status === 200) {
          setDetailsData({ loading: true, data: res?.docs });
          setFieldsValue({
            fromMail: res?.docs?.fromMail || "",
            fromName: res?.docs?.fromName || "",
            subject: res?.docs?.subject || "",
            content: res?.docs?.content || "",
            toMail: res?.docs?.toMail || "",
            fromMail: res?.docs?.fromMail || "",
            ccMail: res?.docs?.ccMail || "",
          });
          setContent(res?.docs?.content);
        }
      });
    }
  }, [id]);

  useEffect(() => {
    getDetails();
  }, [getDetails]);

  const onSubmit = async (values) => {
    setLoaded(true);
    try {
      const res = await editEmail({
        id: id,
        fromMail: values?.fromMail || "",
        fromName: values?.fromName || "",
        subject: values?.subject || "",
        content: content || "",
        toMail: values?.toMail || "",
        fromMail: values?.fromMail || "",
        ccMail: values?.ccMail,
      });

      if (res?.data?.status === 200 || res?.data?.status === 201) {
        navigate("/admin/email-template");
        toast.info(res?.data?.message);
      } else {
        toast.info(
          res?.data?.message || res?.data?.errors || "Something went wrong"
        );
      }

      setLoaded(false);
    } catch (err) {
      setLoaded(false);
      // console.error(err);
      toast.error("Somthing went wrong !!!");
    }
  };

  return (
    <div>
      <form onSubmit={(event) => handleSubmit(event, onSubmit)}>
        <PageHeader
          title={
            detailsData?.data?.id
              ? "Edit Email Template"
              : "Add New Email Template"
          }
          headerActions={() => (
            <div className="flex items-center space-x-3">
              <Button
                buttonHasLink={true}
                buttonLink={"/admin/email-template"}
                buttonType={"button"}
                buttonIcon={"fa-regular fa-xmark"}
                buttonIconPosition={"left"}
                buttonLabel={"Cancel"}
                buttonClasses={"!bg-white !text-primary"}
              />
              <Button
                buttonHasLink={false}
                buttonType={"submit"}
                buttonIcon={"fa-light fa-floppy-disk"}
                buttonIconPosition={"left"}
                buttonClasses={"!bg-primary !text-org-silver"}
                buttonLabel={"Save"}
                isDisable={loaded}
              />
            </div>
          )}
        />
        <div className="py-2 px-6">
          <div className="w-full bg-white rounded-md shadow  py-5 px-4">
            <div className="grid grid-cols-4 gap-4 divide-x divide-slate-200">
              <div className="col-span-4">
                <div className="space-y-3">
                  <div className="grid grid-cols-12 gap-4">
                    <div className="relative col-span-6">
                      <Input
                        isInputGroup={true}
                        label={"From Mail"}
                        labelClasses={"!text-base"}
                        inputType={"text"}
                        value={values.fromMail}
                        inputName={"fromMail"}
                        onChange={handleChange}
                        inputPlaceholder={"Enter From Mail"}
                        {...formValidate(errors, "fromMail")}
                      />
                    </div>
                    <div className="relative col-span-6">
                      <Input
                        isInputGroup={true}
                        label={"From Name"}
                        labelClasses={"!text-base"}
                        inputType={"text"}
                        value={values.fromName}
                        inputName={"fromName"}
                        onChange={handleChange}
                        inputPlaceholder={"Enter From Name"}
                        {...formValidate(errors, "fromName")}
                      />
                    </div>
                    <div className="relative col-span-6">
                      <Input
                        isInputGroup={true}
                        label={"To Mail"}
                        labelClasses={"!text-base"}
                        inputType={"text"}
                        value={values.toMail}
                        inputName={"toMail"}
                        onChange={handleChange}
                        inputPlaceholder={"Enter To Mail"}
                        // {...formValidate(errors, "toMail")}
                      />
                    </div>
                    <div className="relative col-span-6">
                      <Input
                        isInputGroup={true}
                        label={"CC Mail"}
                        labelClasses={"!text-base"}
                        inputType={"text"}
                        value={values.ccMail}
                        inputName={"ccMail"}
                        onChange={handleChange}
                        inputPlaceholder={"Enter CC Mail"}
                        // {...formValidate(errors, "toMail")}
                      />
                    </div>
                    <div className="relative col-span-12">
                      <Input
                        isInputGroup={true}
                        label={"Subject"}
                        labelClasses={"!text-base"}
                        inputType={"text"}
                        value={values.subject}
                        inputName={"subject"}
                        onChange={handleChange}
                        inputPlaceholder={"Enter Subject"}
                        {...formValidate(errors, "subject")}
                      />
                    </div>
                    <div className="relative col-span-12">
                      {/* <TextEditor
                        label="Content"
                        value={content}
                        onChange={(e) => setContent(e)}
                      /> */}
                      <Editor
                        apiKey={tinymceApiKey}
                        onInit={(evt, editor) => (editorRef.current = editor)}
                        value={content}
                        init={{
                          height: 500,
                          menubar: false,
                          plugins: [
                            "code",
                            "advlist autolink lists link image charmap print preview anchor",
                            "searchreplace visualblocks code fullscreen",
                            "insertdatetime media table paste code help wordcount",
                          ],
                          toolbar:
                            "code | undo redo | formatselect | " +
                            "bold italic backcolor | alignleft aligncenter " +
                            "alignright alignjustify | bullist numlist outdent indent | " +
                            "removeformat | help ",
                          content_style:
                            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                        }}
                        onEditorChange={(newText) => setContent(newText)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-8 pt-4 border-t border-slate-200">
              <div className="flex items-center space-x-3">
                <Button
                  buttonHasLink={true}
                  buttonLink={"/admin/email-template"}
                  buttonType={"button"}
                  buttonIcon={"fa-regular fa-xmark"}
                  buttonIconPosition={"left"}
                  buttonLabel={"Cancel"}
                  buttonClasses={
                    "!bg-white !text-primary !border-primary !border-2"
                  }
                />
                <Button
                  buttonHasLink={false}
                  buttonType={"submit"}
                  buttonIcon={"fa-light fa-floppy-disk"}
                  buttonIconPosition={"left"}
                  buttonClasses={"!bg-primary !text-org-silver"}
                  buttonLabel={"Save"}
                  isDisable={loaded}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditEmailTemplate;
