import React, { useState, useEffect } from "react";
import Input from "../form/Input";
import TextAreaAutoSize from "../form/TextareaAutoSize";
import Button from "../form/Button";
import toastr from "toastr";
import { apiService } from "../../services/api";
import { formValidate } from "../../helpers/formValidate";
import useForm from "../../hooks/useForm";
import { toast } from "react-toastify";
import { addContactQuery } from "../../services/homeService";

const ContactForm = () => {
  const validation = {
    firstName: { required: true, message: "Please enter your first name !!!" },
    lastName: { required: true, message: "Please enter your last name !!!" },
    email: { required: true, message: "Please enter your email !!!" },
    phone: { required: true, message: "Please enter your phone !!!" },
  };
  const {
    values,
    handleChange,
    handleSubmit,
    errors,
    setFieldsValue,
    resetField,
  } = useForm({}, validation);
  const [message, setMessage] = useState("");
  const [loaded, setLoaded] = useState(false);

  const onSubmit = async (values) => {
    setLoaded(true);
    try {
      const res = await addContactQuery({
        firstName: values?.firstName,
        lastName: values?.lastName,
        email: values?.email,
        phone: values?.phone,
        message: message,
      });

      if (res?.data?.status === 200 || res?.data?.status === 201) {
        toast.success(res?.data?.message);
        resetField({ firstName: "", lastName: "", email: "", phone: "" });
        setMessage("");
      } else {
        toast.info(
          res?.data?.message || res?.data?.errors || "Something went wrong"
        );
      }

      setLoaded(false);
    } catch (err) {
      setLoaded(false);
      // console.error(err);
      toast.error("Somthing went wrong !!!");
    }
  };

  return (
    <>
      <form onSubmit={(event) => handleSubmit(event, onSubmit)}>
        <div className="grid sm:grid-cols-2 gap-4">
          <div className="mb-3">
            <Input
              isInputGroup={true}
              label={"First Name"}
              labelClasses={"!text-base"}
              inputType={"text"}
              value={values.firstName}
              inputName={"firstName"}
              onChange={handleChange}
              inputPlaceholder={"Enter First Name"}
              {...formValidate(errors, "firstName")}
            />
          </div>
          <div className="mb-3">
            <Input
              isInputGroup={true}
              label={"Last Name"}
              labelClasses={"!text-base"}
              inputType={"text"}
              value={values.lastName}
              inputName={"lastName"}
              onChange={handleChange}
              inputPlaceholder={"Enter Last Name"}
              {...formValidate(errors, "lastName")}
            />
          </div>
        </div>
        <div className="grid sm:grid-cols-2 gap-4">
          <div className="mb-3">
            <Input
              isInputGroup={true}
              label={"Phone"}
              labelClasses={"!text-base"}
              inputType={"text"}
              value={values.phone}
              inputName={"phone"}
              onChange={handleChange}
              inputPlaceholder={"Enter Phone"}
              {...formValidate(errors, "phone")}
            />
          </div>
          <div className="mb-3">
            <Input
              isInputGroup={true}
              label={"Email"}
              labelClasses={"!text-base"}
              inputType={"text"}
              value={values.email}
              inputName={"email"}
              onChange={handleChange}
              inputPlaceholder={"Enter Email"}
              {...formValidate(errors, "email")}
            />
          </div>
        </div>
        <div className="mb-3">
          <TextAreaAutoSize
            label={"Message"}
            labelClasses={"!text-base"}
            inputValue={message}
            inputName={"message"}
            onChange={(e) => setMessage(e.target.value)}
            inputPlaceholder={"Enter Message"}
          />
        </div>
        <div className="mb-3">
          <Button
            buttonHasLink={false}
            buttonType={"submit"}
            buttonClasses={"!w-full"}
            buttonLabel={"Submit"}
            isDisable={loaded}
            buttonLabelClasses={"uppercase font-semibold !text-sm"}
            buttonEffect={"filled"}
          />
        </div>
      </form>
    </>
  );
};

export default ContactForm;
