import React, { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { usePopper } from "react-popper";
import { useRef, useState } from "react";
import { bottom, top, left, right } from "@popperjs/core";
import { classNames } from "../../../helpers/classNames";
import { Link } from "react-router-dom";

const TableAction = ({ data, xPlacement, dropdownContainer, ...props }) => {
  const popperElRef = useRef(null);
  const [targetElement, setTargetElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);

  const placements = {
    bottom: bottom,
    bottomLeft: "bottom-start",
    bottomRight: "bottom-end",
    top: top,
    topLeft: "top-start",
    topRight: "top-end",
    right: right,
    left: left,
    "": bottom,
  };

  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement: placements[xPlacement],
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 8],
        },
      },
    ],
  });

  
  return (
    <>
      <Menu as="div">
        {({ open }) => (
          <>
            <Menu.Button
              ref={setTargetElement}
              className="flex items-center justify-center"
            >
              <div className="relative text-base text-org-grayB bg-white shadow w-6 h-6 flex items-center justify-center focus:ring-0 transition-all duration-200 rounded-md hover:bg-org-silver hover:text-org-darkGray">
                <i className="fa-regular fa-fw fa-ellipsis-vertical"></i>
              </div>
            </Menu.Button>
            <Transition
              as={Fragment}
              className="z-50"
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
              beforeEnter={() => setPopperElement(popperElRef.current)}
              afterLeave={() => setPopperElement(null)}
              ref={popperElRef}
              style={styles.popper}
              {...attributes.popper}
            >
              <Menu.Items
                className={classNames(
                  "absolute mt-1 border border-org-border w-40 origin-top-right rounded-md bg-white shadow-lg focus:outline-none py-1",
                  dropdownContainer
                )}
              >
                <div className="py-1 max-h-80 overflow-auto scroll-smooth scrollbar">
                  {data.length > 0 &&
                    data?.map((item) =>
                      item.link ? (
                        <Menu.Item key={item._id}>
                          {({ active }) => (
                            <Link
                              to={item.link}
                              className={classNames(
                                "group flex w-full items-center px-3 py-1.5 text-sm gap-2 transition-all duration-200",
                                active
                                  ? "bg-org-veryLightBlue text-org-blue"
                                  : "text-org-grayB",
                                item.class
                              )}
                            >
                              {item.icon && (
                                <i
                                  className={classNames("fa-fw", item.icon)}
                                ></i>
                              )}
                              <span>{item.name}</span>
                            </Link>
                          )}
                        </Menu.Item>
                      ) : (
                        <Menu.Item key={item._id}>
                          {({ active }) => (
                            <button
                              type="button"
                              className={classNames(
                                "group flex w-full items-center px-3 py-1.5 text-sm gap-2 transition-all duration-200",
                                active
                                  ? "bg-org-veryLightBlue text-org-blue"
                                  : "text-org-grayB",
                                item.standout
                                  ? "!text-org-red hover:!bg-org-red/10"
                                  : "",
                                item.class
                              )}
                              onClick={item.onClick}
                            >
                              {item.icon && (
                                <i
                                  className={classNames("fa-fw", item.icon)}
                                ></i>
                              )}
                              <span>{item.name}</span>
                            </button>
                          )}
                        </Menu.Item>
                      )
                    )}
                </div>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </>
  );
};

export default TableAction;
